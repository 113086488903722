.admin-list{
    background-color: #fff;
    border: 1px solid #f0f0f0;
}
.admin-list th{
    padding: 20px;
    font-size: 13px;
    font-weight: normal;
    white-space: nowrap;
}
.admin-list td{
    min-width: 50px;
    border-top: 1px solid #f0f0f0;
    padding: 25px;
}
.admin-list .action-button{
    display: flex;
    align-items: center;
    width: 100%;
}
.admin-list .action-button>button{
    width: 100px;    
    border: none;
    border-radius: 0px;
    font-size: 13px;
    padding: 0.675rem;
    font-weight: bold;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
}
.admin-list .action-button>button:focus, .admin-list .name>div>button:focus{
    box-shadow: none;
}
.admin-list .name>div>button{
    width: 80px;
    border: none;
    border-radius: 0px;
    font-size: 12px;
    padding: 4px 0px;
    font-weight: bold;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
    margin-top: 2px;
}
.admin-list .name .active{
    background-color: #f9b115;
    color: #fff;
}
.admin-list .name .unactive{
    background-color: #ced2d8;
    color: var(--color-text-bold);
    text-align: center;
}
.admin-list .action-button .edit{
    background-color: var(--color-secondary);
    color: #fff;
}
.admin-list .action-button .cancel{
    background-color: var(--color-danger);
    color: #fff;
}
.admin-list .action-button .publish{
    background-color: var(--color-active);
    color: #fff;
}
.admin-list .action-button .delete{
    margin-left: 1em;
    border-color: rgb(229, 83, 83);
    width: 50px;
}
.admin-list .product img{
    width: 50px;
    max-height: 50px;
    object-fit: cover;
    margin-right: 10px;
}
.admin-list .filter{
    display: flex;
    align-items: center;
}
.admin-list .filter .group-control{
    display: flex;
    width: 100%;
    justify-content: center;
}
.admin-list .filter .group-control>button{
    border-radius: 0px;
    min-width: 100px;
}
.admin-list .filter .group-control .btn-outline-primary{
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}
.admin-list .filter .group-control .btn-outline-primary:focus, .admin-list .filter .group-control .btn-outline-primary:active, .admin-list .filter .group-control .btn-primary:focus{
    box-shadow: 0 0 0 0.2rem rgb(80 58 32 / 24%);
    color: #fff;
    background-color: var(--color-secondary);
}
.admin-list .filter .group-control .btn-outline-primary:hover, .admin-list .filter .group-control .btn-primary:hover{
    color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}
.admin-list .filter .search{
    width: 100%;
    display: inline-flex;
}
.admin-list .filter .search .form-control:focus{
    box-shadow: 0 0 0 0.2rem rgb(80 58 32 / 24%);
    border-color: var(--color-secondary);
}
.admin-list .filter .add-btn{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.admin-list .filter .add-btn>button{
    border-radius: 0px;
}
.admin-list tr:hover{
    background-color: #ebedef6b;
}