.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333840 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**/
@keyframes ldio-sj8a5p1t26 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(22.5deg) }
  100% { transform: rotate(45deg) }
}
.ldio-sj8a5p1t26 > div {
  transform-origin: 100px 100px;
  animation: ldio-sj8a5p1t26 0.5s infinite linear;
}
.ldio-sj8a5p1t26 > div div {
    position: absolute;
    width: 22px;
    height: 152px;
    background: #3e6d8d;
    left: 100px;
    top: 100px;
    transform: translate(-50%,-50%);
}
.ldio-sj8a5p1t26 > div div:nth-child(1) {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.ldio-sj8a5p1t26 > div div:nth-child(6) {
    width: 80px;
    height: 80px;
    background: #f1f2f3;
    border-radius: 50%;
}.ldio-sj8a5p1t26 > div div:nth-child(3) {
  transform: translate(-50%,-50%) rotate(45deg)
}.ldio-sj8a5p1t26 > div div:nth-child(4) {
  transform: translate(-50%,-50%) rotate(90deg)
}.ldio-sj8a5p1t26 > div div:nth-child(5) {
  transform: translate(-50%,-50%) rotate(135deg)
}
.loadingio-spinner-gear-5l2qzh0oms {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-sj8a5p1t26 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-sj8a5p1t26 div { box-sizing: content-box; }
/* generated by https://loading.io/ */