.sc_card_search_cat {
  height: 150px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px !important;
  height: max-content;
}
.sc_card_search_cat > a {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.sc_card_search_cat > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sc_card_search_cat > a > span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 30px;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-secondary);
}

.product-list .sc_card_search_cat{
  height: 200px;
}
@media (max-width: 576px) {
  .sc_card_search_cat{
    width: 100% !important;
    height: 150px !important;
  }
  .sc_card_search_cat > a > span{
    padding: 10px;
    font-size: 13px;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%,-50%);
  }
}