.sc_login{
    max-width: 600px;
    margin: auto;
    border: 1px solid #e9e9e9;
    background-color: #fff;
}
.sc_login .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.sc_login .title{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--color-secondary);
}
.sc_login .container .lg_fb:focus{
    box-shadow: 0 0 0 0.2rem rgb(154 183 243 / 56%);
}
.sc_login .container .lg_gg:focus{
    box-shadow: 0 0 0 0.2rem rgb(212 249 222 / 50%);
}
.sc_login .container .lg_fb>i, .sc_login .container .lg_gg>i{
    margin-right: 5px;
}
.sc_login .container .lg_fb{
    background-color: #435d93;
    color: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
}
.sc_login .container>button{
    font-family: inherit !important;
    font-size: 0.875rem !important;
    margin: 4px 0px;
}
.sc_login .container .lg_gg{
    border: 2px solid #e9e9e9e1;
}
.sc_login .container .lg_gg>i{
    color: #32a852;
}
.sc_login .c-app{
    height: auto;
    min-height: 0;
}
.sc_login .col-md-8{
    max-width: 100%;
    flex: none;
    padding: 0;
}
.sc_login .justify-content-center{
    width: 100%;
}
.sc_login_type1{
    display: flex;
    align-items: center;
    line-height: 42px;
    margin-left: 10px;
}
.sc_login_type1 .lg{
    margin-left: 5px;
}
.sc_login_type1 .lg>button{
    font-size: 0.875rem !important;
    font-family: inherit !important;
    font-weight: 500 !important;
    height: 35px;
    width: 35px !important;
    vertical-align: middle;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #ededed !important;
    color: inherit !important;
    border-radius: 50px !important;
    border: 0px !important;
    padding: 2px !important
}
.sc_login_type1 .lg>button:focus, .sc_login_type2 .lg>button:focus{
    box-shadow: none;
}
.sc_login_type1 .lg>button>span{
    border-radius: 50px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sc_login_type1 .lg._fb span{
    background-color: #1e4297;
    color: #fff;
    font-size: 176x;
}
.sc_login_type2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.sc_login_type2 .lg>button{
    font-size: 0.875rem !important;
    font-family: inherit !important;
    font-weight: bold !important;
    height: 36px;
    width: 100% !important;
    vertical-align: middle;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    background-color: #ededed !important;
    color: var(--color-secondary) !important;
    border-radius: 50px !important;
    border: 0px !important;
    padding: 0.2rem 0.2rem 0.2rem 1rem !important;
}
.sc_login_type2 .lg{
    width: 40%;
}
.sc_login_type2 .lg._fb{
    margin-right: 0.5rem;
}
.sc_login_type2 .lg>button>span{
    border-radius: 50px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}
.sc_login_type2 .lg._fb span{
    background-color: #1e4297;
    color: #fff;
    font-size: 176x;
}
@media (min-width: 481px){
    .sc_login .container{
        padding: 30px 45px;
    }
}
@media (min-width: 769px){
    .sc_login .container{
        padding: 30px 60px;
    }
}
@media (max-width: 480px) {
    .sc_login_type2 .lg{
        width: 100%;
    }
}