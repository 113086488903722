.mini-shopping-cart {
    font-size: 13px;
}
.mini-shopping-cart .items{
    max-height: 70vh;
    overflow-y: auto;
}
.mini-shopping-cart .totals {
    margin: -1px 0 0;
    background-color: #f9f9f9;
    padding: 15px;
    font-weight: bold;
    color: var(--color-text-bold);
    text-transform: uppercase;
}
.mini-shopping-cart .totals strong {
    margin: 0 0 0 5px;
    font-size: 14px;
    color: var( --color-primary);
}
.mini-shopping-cart .buttons>a>button{
    background-color: var(--color-secondary);
    color: #fff;
    min-width: 145px;
    border: none;
    padding: 13px 25px;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
}
.mini-shopping-cart .buttons {
    padding: 20px 15px;
}
@media (min-width: 1281px){
    .flyout-cart {
        display: none;
        position: absolute;
        right: 0;
        z-index: 10;
        width: 350px;
        box-shadow: 0 1px 8px rgba(0,0,0,.1);
        background: #fff;
    }
    .mini-shopping-cart {
        text-align: center;
    }
    .mini-shopping-cart .totals {
        text-align: center;
    }
    .mini-shopping-cart .buttons {
        overflow: hidden;
        padding: 12px 15px;
        text-align: center;
    }
}
@media (max-width: 576px) {
    .mini-shopping-cart .items{
        max-height: calc(100vh - 300px);
    }
}