.wishlist-content .table-wrapper {
  margin: 0 0 20px;
}

.wishlist-content .buttons {
  margin: 0 0 15px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 40px;
  font-size: 0;
}

.buttons {
  text-align: center;
}

.wishlist-content .buttons:after {
  content: "";
  display: block;
  clear: both;
}

.footable-first-column {
  text-align: center;
}

.cart th,
.data-table th,
.forums-table-section th {
  padding: 20px;
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
}

.cart .remove-from-cart,
.cart .add-to-cart,
.data-table .select-boxes,
.data-table .order {
  text-align: center;
}

.cart .remove-from-cart {
  color: var(--color-danger);
}

.cart th,
.data-table th,
.forums-table-section th {
  padding: 20px;
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
}

.cart .product-picture {
  text-align: center;
}

.cart .product,
.data-table .product,
.data-table .info,
.data-table .name,
.forum-table .forum-details,
.forum-table .topic-details {
  text-align: left;
}

.cart .unit-price {
  white-space: nowrap;
}

.cart th.subtotal {
  text-transform: none;
}

.cart .subtotal {
  font-weight: bold;
  color: #00a1b1;
}

.cart .subtotal,
.mini-shopping-cart .price strong,
.mini-shopping-cart .totals strong,
.item-box .actual-price,
.overview .product-price,
.variant-overview .product-price,
.compare-products-table .product-price td,
.cart-total .order-total,
.productAddedToCartWindowDescription .price {
  color: var(--color-price);
}

.footable-first-column {
  text-align: center;
}

div.footable-toggle {
  display: none;
  position: absolute;
  /* left: 50%; */
  width: 32px;
  height: 32px;
  margin: -5px 0 0 -16px;
  overflow: hidden;
  background-color: #f93b54;
  background-image: url(/images/ajuma/table-sprite.png);
  background-repeat: no-repeat;
}

.footable-detail-show.footable-toggle {
  background-position: center -32px;
}

.footable-row-detail {
  text-align: left;
}

.footable-row-detail-cell {
  text-align: left !important;
}

.footable-row-detail-row {
  margin: 0 0 10px;
  display: table-row;
}

.footable-row-detail-name {
  display: table-cell;
  /* padding: 10px; */
  min-width: 75px;
}

.footable-row-detail-value {
  display: table-cell;
  padding: 10px;
}

.order-details-page .order-overview {
  margin: 0 0 20px 0px;
  padding: 0px;
  font-size: 15px;
}

.order-details-page .order-info span {
  display: block;
  margin: 5px 0;
}

.order-review-data,
.order-details-area,
.shipment-details-area {
  margin: 0 0 20px;
  overflow: hidden;
}

.order-review-data li,
.order-details-area li,
.shipment-details-area li {
  padding: 2px 0;
}

.order-review-data ul,
.order-details-area ul,
.shipment-details-area ul {
  margin: 20px 0 0;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 0 10px 10px;
}

.order-review-data .title,
.order-details-area .title,
.shipment-details-area .title {
  display: block !important;
  margin: 20px 0 5px;
  background-color: transparent;
  padding: 0;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.order-details-page .section,
.shipment-details-page .section {
  margin: 0 0 10px;
}

.order-details-page .section .title,
.shipment-details-page .section .title {
  display: none;
  margin-top: 0px;
}

.fieldset .title,
.section .title {
  background-color: #f9f9f9;
  padding: 25px 30px;
  font-size: 15px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.cart,
.data-table,
.compare-products-table,
.forums-table-section table {
  border: 1px solid #e9e9e9;
  background-color: #fff;
}

.order-details-page .actions {
  margin: 20px 0 0;
  font-size: 0;
}

.order-details-page .actions .re-order-button {
  color: #fff;
  background-color: var(--color-secondary);
  padding: 0px;
}

.pager .individual-page span {
  background-color: transparent;
  color: #888888;
  cursor: pointer;
}

.pager li.active {
  background-color: var(--color-secondary);
  color: #fff;
  cursor: default;
  outline: none;
}

.pager li>a:focus {
  outline: none;
}

.pager li.active>a:hover {
  color: #fff !important;
}

.pager li {
  background-color: transparent;
  color: #888888;
  cursor: pointer;
}

.order-details-page .actions button {
  width: 210px;
  max-width: 100%;
  height: 45px;
  margin: 0 5px 10px;
  border: medium none;
  padding: 0 28px 0 48px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  outline: 2px solid transparent;
  transition: all 0.2s ease 0s;
}

.order-details-page .totals {
  margin: 20px 0 0;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 20px 0;
}

.order-details-page .total-info {
  width: 300px;
  max-width: 80%;
  margin: 0 auto;
}

.cart-total td.cart-total-left {
  text-align: left;
}

.cart-total td {
  width: 50%;
  padding: 5px;
}

.cart-total td.cart-total-right {
  text-align: right;
}

.pagination {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination.paper {
  position: relative;
  margin: 0 0 20px;
}

.pagination.paper>li {
  margin: 0 2px;
}

.pagination.paper>li>a {
  padding: 10px 15px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.pagination.paper .active>a {
  background-color: var(--color-secondary);
  color: #fff;
}

.blogpost-page .page-body,
.news-item-page .page-body {
  border: 1px solid #e9e9e9;
  background-color: #fff;
}

.blogpost-page .post {
  position: relative;
}

.blogpost-page .post,
.news-item-page .news-item,
.comment-list .comment,
.new-comment .form-fields {
  padding: 30px;
}

.blogpost-page .page-title,
.news-item-page .page-title {
  display: block !important;
  margin: 0 0 10px;
  border: none;
  padding: 0;
  text-transform: none;
}

.blogpost-page .page-title {
  margin: 0 0 10px;
  padding: 0 !important;
}

.blogpost-page .post-date {
  margin: 0 0 12px;
}

.blogpost-page .rich-blog-image {
  margin: 0 0 40px;
}

.blogpost-page .rich-blog-image img {
  display: block;
  width: 100%;
  margin: auto;
  object-fit: cover;
  max-height: 500px;
}

.blogpost-page .post-body {
  text-align: justify;
}

.fieldset,
.section {
  position: relative;
  margin: 0 0 30px;
}

.fieldset .title,
.section .title {
  background-color: #f9f9f9;
  padding: 25px 30px;
  font-size: 15px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.form-fields {
  position: relative;
}

.inputs {
  position: relative;
  margin: 0 0 20px;
  white-space: nowrap;
  font-size: 0;
}

.new-comment .inputs {
  text-align: center;
}

/* .inputs label {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  font-size: 13px;
  font-weight: bold;
} */
.new-comment .inputs label {
  display: none;
}

.new-comment textarea {
  min-height: 180px;
}

.new-comment .buttons {
  padding: 0 0 10px;
}

.buttons {
  text-align: center;
}

.new-comment .button-1 {
  border: none;
  background-color: var(--color-secondary);
  padding: 15px 30px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.address-page .address-header {
  width: 100%;
  display: inline-block;
}

.address-page .address-header>button {
  float: right;
  padding: 9px 22px;
  background-color: var(--color-secondary);
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0px;
}

.address-page .address-header>button:focus {
  border-radius: 0px;
  box-shadow: 0 0 0 0.2rem rgb(80 58 32 / 28%);
}

.bill-page .bill-tab {
  display: flex;
}

.bill-page .bill-tab>button {
  padding: 9px 22px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease;
  border-radius: 0px;
}

.bill-page .bill-tab>button:focus {
  box-shadow: none;
}

.bill-page .bill-tab>button.active {
  background-color: var(--color-secondary);
  color: #fff;
}

.search-box-search {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 25px 30px;
}

.search-box-search .search-box-text {
  width: 100%;
  height: 40px;
  outline: none;
}

.search-box-search .btn-search {
  min-width: 100px;
  border: none;
  border-radius: 0px;
  background-color: var(--color-secondary);
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
  float: right;
  height: 40px;
}

.bill {
  display: flex;
}

.bill-info,
.bill-status {
  background-color: #fff;
  padding: 10px 20px;
  margin: 10px 0px;
  width: 100%;
  text-align: left;
  font-size: 13px;
}

.bill-info .title,
.bill-status .title {
  font-size: 15px;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.bill-info {
  margin-right: 5px;
}

.bill-status {
  margin-left: 5px;
}

.bill-status .container {
  margin: 10px 0px;
  padding: 0px;
}

.bill-info .name {
  font-weight: bold;
  margin-bottom: 10px;
}

.bill-info .address {
  margin-top: 10px;
  font-weight: 500;
}

.timeline-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 50px;
}

.timeline-item .shape {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* margin-top: 5px; */
  height: 100%;
}

.timeline-item .text {
  margin-left: 10px;
  margin-top: -5px;
  color: #939393;
}

.timeline-item .shape .circle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #939393;
}

.timeline-item .shape .line {
  width: 2px;
  min-height: 10px;
  height: 40px;
  background-color: #898989;
}

.edit-content {
  display: flex;
  justify-content: flex-end;
}

.edit-content>button,
.edit-content>button:hover,
.btn-save-edit {
  background-color: var(--color-secondary);
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 13px;
  padding: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.edit-content>button:focus,
.btn-save-edit {
  box-shadow: none;
  outline: none !important;
}

.admin-page .tab-header,
.quick-order .tab-header,
.sc_modal_select_photos .tab-header {
  display: flex;
}

.admin-page .tab-header .label,
.quick-order .tab-header .label,
.sc_modal_select_photos .tab-header .label {
  position: relative;
  display: block;
  padding: 15px 22px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-page .tab-header .label,
.quick-order .tab-header .label,
.sc_modal_select_photos .tab-header .label {
  color: #fff;
  background-color: var(--color-secondary);
}

.admin-page .tab-header .label.inactive,
.quick-order .tab-header .label.inactive,
.sc_modal_select_photos .tab-header .label.inactive {
  color: var(--color-secondary);
  background-color: transparent;
}

.view-detail {
  color: var(--color-primary);
  text-decoration: underline;
  padding: 5px;
  text-decoration: underline;
  line-height: 2em;
}

.theme-ajumashop .slick-prev:before,
.slick-next:before {
  font-size: 25px;
  color: var(--color-slide-button);
}

.theme-ajumashop .slick-next {
  right: 20px;
}

.theme-ajumashop .slick-prev {
  left: 10px;
  z-index: 1;
}

.theme-ajumashop input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.theme-ajumashop input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: var(--color-secondary);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.cart .cart-header-row th {
  font-weight: bold;
  color: var(--color-text-bold);
}

.html-content ul,
.html-content ol {
  list-style: inside;
}

.quick-order .mf-container {
  width: 100%;
  padding: 0px;
  margin: 0px 5px;
}

.quick-order .col-lg-3 {
  flex: 0 0 100%;
  max-width: 100%;
}

.quick-order .input-group {
  flex-wrap: nowrap;
}

.quick-order .pagination_style {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.quick-order .pagination_style li {
  width: 100%;
  max-width: 30px;
  min-height: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.quick-order .pagination_style li.active {
  background-color: var(--color-secondary);
  color: #fff;
}

.quick-order .pagination_style a:focus {
  outline: #fff;
}

.quick-order .review {
  color: #fff;
  background-color: var(--color-hotline);
  border-color: var(--color-secondary);
  border: none;
  border-radius: 0px;
  padding: 0.475rem 0.75rem;
  outline: none;
  height: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 5px;
}

.quick-order .review.active {
  background-color: var(--color-secondary);
}

.quick-order .cart td {
  padding: 20px;
}

.quick-order .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quick-order .total-money {
  background-color: #fff;
  padding: 0.8rem 0.75rem;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-primary);
}

.quick-order .total-money>strong {
  float: right;
  margin-left: 0.5rem;
}

.quick-order .add-to-cart-button {
  background-color: var(--color-secondary);
  color: #fff;
  width: 180px;
  max-width: 100%;
  height: 45px;
  margin: 0 0 10px;
  border: none;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.quick-order .cart .quantity {
  text-align: center;
}

.quick-order .input-group-suffix>button {
  height: 36px;
}

.quick-order .cart .product-picture {
  text-align: left;
  width: 80px;
  min-width: 80px !important;
}

.quick-order .product-sorting {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quick-order .product-sorting select {
  outline: none;
  height: 36px;
  margin-left: 5px;
}

/* .quick-order .cart .quantity .quantity-control > button {
  display: block;
} */
.quick-order .cart td,
.quick-order .cart th {
  padding: 10px;
}

.quick-order .cart .product-picture img {
  height: 60px;
}

.quick-order .cart .subtotal {
  text-align: center;
}

.ck-editor__top {
  position: sticky !important;
  top: 0px;
  z-index: 1071;
}

.modal-body .html-content {
  width: 100%;
  overflow-y: overlay;
  max-height: 525px;
}

.modal-body .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  /* position: sticky; */
  top: 110px;
}

.shopping-cart-page .panel-group-left .title {
  padding-left: 10px;
}

.shopping-cart-page .panel-group-right .select-shipping-address .title {
  text-align: center;
}

.cart-total .order-total label {
  margin-bottom: 0px;
}

.shopping-cart-page .panel-group-left .section {
  margin-bottom: 0px;
}

.item-box .product-active,
.item-box .product-inactive {
  width: 36px;
  height: 36px;
  margin: 0 3px 0 0;
  background-color: #f6f6f6;
  display: block;
  border: 0px;
  font-size: 13px;
  outline: none;
}

.item-box .product-active i {
  color: var(--color-active);
}

.item-box .product-inactive i {
  color: var(--color-danger);
}

.product-grid .item-box .buttons-upper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quickorder-user .page-body {
  display: flex;
}

.dFaSjCw100 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.dFaSjSw100 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.dFaCjCw100 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dFaCjEw100 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.dFaEjEw100 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.dFaEjSw100 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
}

.dFaCjFSw100 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.dFaCjSw100 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dFaCjCtaCw100 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.dFfCaSjCw100 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.dFfCaSjFSw100 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.dFfCaEjCw100 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.mb-05rem {
  margin-bottom: 0.5rem;
}

.shopping-cart-page .cart th {
  padding: 20px 10px;
}

.productAddedToCartOptions-group .title-group {
  margin-right: 0.5rem;
  min-width: 60px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.productAddedToCartWindowSummary button:disabled,
.overview .add-to-cart-button:disabled {
  background-color: var(--color-secondary-disabled);
}

.product-variation__tick:before {
  border: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid var(--color-primary-bold);
  content: "";
  position: absolute;
  right: -0.9375rem;
  bottom: 0;
}

.product-variation__tick>.icon-tick-bold {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 8px;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.productAddedToCartOptions {
  margin: 1.5rem 0px;
}

.details .master-column-wrapper {
  position: relative;
  /* margin-top: 0px;
  padding-top: 135px; */
}

.category-page .filter-price-mobile .sc_filter {
  display: none;
}

.shopping-cart-page .panel-group-left .address-grid .sc_input_radio {
  width: 32%;
  margin-right: 0.2rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border: 0;
}

.quick-order .number_result_filter {
  position: absolute;
  top: 3px;
  right: 3px;
  /* width: 20px;
  height: 20px;
  border-radius: 50px; */
  color: var(--color-secondary);
  /* background-color: #fff; */
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.quick-order .tab-header .label.inactive {
  background-color: #f6f6f6;
}

.quick-order .tab-header .title-grp.active .number_result_filter {
  color: #fff;
}

.quick-order .tab-header .title-grp {
  margin: 2px;
  position: relative;
}

/* .quick-order .tab-header .title-grp:first-child {
  margin-left: 2px;
} */

.quick-order .tab-header {
  flex-wrap: wrap;
}

.accept-policy {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.accept-policy>div {
  padding-left: 1rem;
  font-size: 13px;
}

.accept-policy>input {
  height: 17.27px;
}

.accept-policy a {
  text-decoration: underline;
  color: var(--color-secondary);
}

.accept-policy a:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .update-wishlist-button {
    float: left;
  }

  .cart .subtotal {
    white-space: nowrap;
  }

  .order-review-data ul,
  .order-details-area ul,
  .shipment-details-area ul {
    padding: 0px 10px 10px;
  }

  .blogpost-page .post {
    padding: 30px 45px;
  }

  .blog-page .post,
  .news-items .news-item,
  .blogpost-page .post,
  .news-item-page .news-item,
  .comment-list .comment,
  .new-comment .form-fields {
    padding: 30px 45px;
  }

  .inputs textarea {
    padding: 12px;
  }

  .bill-page a.view-detail {
    float: left;
  }

  .order-details-page .data-table th:first-child,
  .order-details-page .data-table td:first-child {
    display: table-cell !important;
  }

  .quick-order .footer {
    flex-direction: column;
    margin: 30px 0px;
  }
}

@media (max-width: 576px) {
  .quick-order .search-box-search {
    flex-direction: column;
  }

  .quick-order .review {
    width: fit-content;
  }

  .quick-order .cart .row-product-name {
    display: table-row !important;
  }

  .quick-order .cart .unit-price,
  .quick-order .cart .quantity,
  .quick-order .cart .row-product-name .product {
    display: table-cell !important;
  }

  .quick-order .cart .cart-item-row td {
    border: none;
  }

  .quick-order .cart .row-product-name td {
    border-top: 1px solid #f0f0f0;
  }

  .quick-order .cart .subtotal,
  .quick-order .cart .item-count {
    display: none !important;
  }

  .cart .remove-from-cart,
  .cart .add-to-cart,
  .cart .sku,
  .cart .product,
  .cart .unit-price,
  .cart .quantity,
  .cart .check-order {
    display: none !important;
  }

  .wishlist .cart .item-count {
    display: table-cell !important;
  }

  div.footable-toggle {
    display: inline;
  }

  .blogpost-page .page-title {
    margin: 10px 0;
  }

  .shopping-cart-page .page-body {
    display: flex;
    flex-direction: column;
  }

  .shopping-cart-page .panel-group-left {
    width: 100%;
  }

  .shopping-cart-page .panel-group-right {
    width: 100%;
    margin-left: 0px;
  }

  .shopping-cart-page .common-buttons .continue-shopping-button {
    padding-left: 45px;
  }

  .bill {
    flex-direction: column;
  }

  .bill-info {
    margin-right: 0px;
  }

  .bill-status {
    margin-left: 0px;
  }

  .theme-ajumashop .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
  }

  .theme-ajumashop .slick-next {
    right: 5px;
  }

  .theme-ajumashop .slick-prev {
    left: 5px;
    z-index: 1;
  }

  .cart tr.n-bdtnp>td {
    border-top: 1px solid #f0f0f0 !important;
  }

  .cart .quantity .quantity-control>button {
    display: none;
  }

  .quick-order .footer {
    flex-direction: column;
    margin: 30px 0px;
  }

  .quick-order .footer .total-money,
  .quick-order .add-to-cart-button {
    width: 300px;
    margin: 5px;
  }

  .cart-total .order-total {
    font-size: 16px !important;
  }

  .blogpost-page .post {
    padding: 30px 20px;
  }

  .product-list .item-box .product-active,
  .product-list .item-box .product-inactive {
    width: 30px;
    height: 30px;
  }

  .bill-page .bill-tab>button {
    padding: 9px 10px;
  }

  .category-page .filter-price-mobile .sc_filter {
    display: block;
  }

  .quick-order .tab-header .title-grp {
    width: 48.5%;
  }

  .quick-order .tab-header .label.inactive,
  .quick-order .tab-header .label {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: all .3s ease;
    padding: 15px 5px;
    background-color: #f6f6f6;
    font-size: 12px;
  }

  .quick-order .tab-header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .quick-order .tab-header .label {
    background-color: var(--color-secondary);
  }

  .shopping-cart-page .panel-group-left .address-grid .sc_input_radio {
    width: 100%;
    margin-right: 0;
    border: 0px;
    box-shadow: none;
  }

  .shopping-cart-page .panel-group-left .address-grid .sc_input_radio_label {
    text-align: left;
  }
}

@media (min-width: 577px) {
  .quick-order .cart .product {
    width: 250px;
  }

  .quick-order .cart .quantity {
    display: table-cell !important;
  }

  .quick-order .cart .subtotal,
  .quick-order .cart .item-count,
  .quick-order .cart .row-product-name {
    display: none !important;
  }

  .quick-order .mf-container {
    width: 50%;
  }

  .quick-order .product-sorting {
    width: 30%;
  }

  .quick-order .review {
    width: 25%;
  }

  .wishlist .cart .remove-from-cart,
  .wishlist .cart .add-to-cart,
  .wishlist .cart .unit-price,
  .wishlist .cart .quantity,
  .cart .check-order {
    display: none;
  }

  div.footable-toggle {
    display: inline;
  }

  .cart .remove-from-cart,
  .cart .add-to-cart,
  .cart .sku,
  .cart .quantity {
    display: none;
  }

  .order-details-page .table-wrapper .sku,
  .order-details-page .table-wrapper .unit-price,
  .order-details-page .table-wrapper .price {
    display: none;
  }

  .order-details-page .section.products .data-table td {
    min-width: 50px !important;
  }

  .cart .quantity .quantity-control>button {
    display: none;
  }

  .item-box {
    background-color: #fff;
  }

  .cart td {
    min-width: 100px !important;
  }

  .bestsellers {
    display: block;
  }
}

@media (min-width: 769px) {
  .quick-order .cart .subtotal {
    min-width: 150px;
  }

  .quick-order .footer {
    flex-direction: row;
  }

  .quick-order .cart .subtotal {
    display: table-cell !important;
  }

  .cart td {
    min-width: 50px !important;
  }

  .cart .quantity .quantity-control>button {
    display: block;
  }

  .order-details-page .section.products .data-table td {
    min-width: 150px !important;
  }

  .order-details-page .data-table th:first-child,
  .order-details-page .data-table td:first-child {
    display: none !important;
  }

  .order-details-page .table-wrapper .sku,
  .order-details-page .table-wrapper .unit-price,
  .order-details-page .table-wrapper .price {
    display: table-cell;
  }

  .wishlist .cart .remove-from-cart,
  .wishlist .cart .add-to-cart,
  .wishlist .cart .unit-price,
  .wishlist .cart .quantity {
    display: table-cell;
  }

  div.footable-toggle {
    display: none;
  }

  .cart .remove-from-cart,
  .cart .add-to-cart,
  .cart .sku,
  .cart .quantity,
  .cart .check-order {
    display: table-cell;
  }

  .cart .toggle-mobile {
    display: none;
  }

  .wishlist .cart .item-count {
    display: none !important;
  }

  .order-details-page .order-overview {
    text-align: left;
  }

  .order-details-page .order-info span {
    display: inline-block;
    margin: 5px 10px 5px 0;
  }

  /* .order-review-data ul, .order-details-area ul {
        float: left;
        width: 65%;
        height: 100%;
        margin: 0 0 0 20px;
        text-align: left;
    }
    .order-details-area .shipping-info{
        float: left;
        width: 34%;
        height: 100%;
        margin: 0 0 0 1%;
        text-align: left;
    } */
  .order-details-page .options {
    text-align: center;
  }

  .order-details-page .actions button {
    margin: 0 0 0 10px;
  }

  .order-details-page .totals {
    width: 100%;
    margin-left: auto;
  }

  .order-review-data ul:first-child,
  .order-details-area ul:first-child,
  .shipment-details-area ul:first-child {
    margin: 0;
  }

  .blogpost-page .page-title {
    margin: 20px 0 10px;
  }

  .blog-page .post,
  .news-items .news-item,
  .blogpost-page .post,
  .news-item-page .news-item,
  .comment-list .comment,
  .new-comment .form-fields {
    padding: 30px 30px;
  }

  .new-comment textarea {
    width: 600px;
  }
}

@media (max-width: 1281px) {
  .shopping-cart-page .panel-group-left {
    width: 100%;
  }

  .shopping-cart-page .panel-group-right {
    width: 100%;
  }

  .cart-total td.cart-total-left,
  .cart-total td.cart-total-right {
    text-align: center !important;
  }

  /* .cart th:first-child, .cart td:first-child {
        display: none!important;
    } */
  .cart .quantity {
    justify-content: center;
  }

  /* .order-details-page .data-table th:first-child, .order-details-page .data-table td:first-child {
        display: none!important;
    } */
  .category-title {
    padding: 5px;
    margin-bottom: 0px;
  }

  .bestsellers .owl-prev,
  .bestsellers .owl-next {
    position: absolute;
    top: -2px;
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    font-size: 0;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .bestsellers {
    /* display: block; */
    position: relative;
    margin: 0 0 60px;
  }

  .bestsellers .owl-prev {
    left: 0;
    background-image: url(/images/ajuma/arrow-prev.png);
    background-position: right center;
  }

  .bestsellers .owl-next {
    right: 0;
    background-image: url(/images/ajuma/arrow-next.png);
    background-position: left center;
  }

  .bestsellers .item-grid {
    position: static;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    background-color: #fff;
  }

  .bestsellers .owl-stage {
    background-color: #fff;
  }

  /* .rich-blog-homepage .owl-carousel .owl-item{
        width: 768px !important;
    } */
  .card_banner {
    height: 300px;
  }

  .bestsellers .owl-prev:hover,
  .bestsellers .owl-next:hover {
    background-color: var(--color-secondary);
    background-position: right center;
  }

  .bestsellers .owl-prev:hover {
    background-position: left center;
  }

  .bestsellers .item-grid {
    position: static;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    background-color: #fff;
  }

  .bestsellers .item-box {
    width: 100% !important;
    margin: 0 !important;
  }

  .item-box:nth-child(2n + 1) {
    clear: both;
    margin-left: 0;
  }

  .bestsellers .product-item {
    margin: 0 0 0 -1px;
    border-width: 0 1px 1px;
    border-color: #f0f0f0;
    padding: 0 !important;
    text-align: left;
  }

  .bestsellers .picture {
    float: left;
    width: 40%;
    margin: 0;
    padding: 20px 10px 20px 20px;
  }

  .bestsellers .details {
    float: right;
    width: 60%;
    padding: 20px 20px 20px 10px;
  }

  .bestsellers .product-title {
    height: 20px;
    margin: 0 0 8px;
    padding: 0;
  }

  .bestsellers .product-title a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .bestsellers .details .prices {
    margin: 0 0 16px;
    padding: 0;
  }

  .bestsellers .buttons-upper,
  .bestsellers .buttons-lower {
    position: static !important;
    float: left;
    width: auto !important;
    height: auto !important;
    border: none !important;
    background-color: transparent;
  }

  .bestsellers input.button-2,
  .bestsellers button.button-2 {
    width: 36px !important;
    height: 36px !important;
    margin: 0 3px 0 0 !important;
    background-color: #888;
  }

  .bestsellers .product-box-add-to-cart-button span {
    background-color: transparent !important;
    font-size: 0 !important;
  }

  .footer-block .list {
    padding: 25px;
  }

  .nopAjaxFilters7Spikes {
    margin-bottom: 2%;
  }

  .footer .center .accepted-payments {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.blogpost-page {
  position: relative;
  top: 60px;
  margin: 0 0 150px;
}

@media (min-width: 1281px) {
  .quick-order .cart .unit-price {
    width: 200px;
  }

  .quick-order .cart .product {
    width: 450px;
  }

  .quick-order .cart .subtotal {
    width: 200px;
  }

  .quick-order .review {
    width: 25%;
  }

  .quick-order .product-sorting {
    width: 30%;
  }

  .quick-order .mf-container {
    width: 80%;
  }

  .wishlist .cart .item-count,
  .quick-order .cart .item-count {
    display: none !important;
  }

  /* .quick-order .cart .product-picture{
        text-align: left;
        width: 100px;
    } */
  .quick-order .cart .quantity {
    text-align: center;
  }

  .order-details-page .data-table col:first-child,
  .order-details-page .data-table th:first-child,
  .order-details-page .data-table td:first-child {
    display: none !important;
  }

  .order-details-page .actions button {
    width: 190px;
  }

  .order-details-page .totals {
    width: 100%;
  }

  .rich-blog-body .center-2 {
    float: none;
    width: auto;
  }

  .rich-blog-body .page-title {
    display: block !important;
    min-height: 40px;
    padding: 0 0 8px;
  }

  .rich-blog-body .blog-page .page-title {
    padding: 0 460px 8px 0;
  }

  .rich-blog-body .center-2 {
    float: none;
    width: auto;
  }

  .blogpost-page {
    top: 39px;
    margin: 0 auto 129px;
  }

  .new-comment textarea {
    display: block;
    margin: auto;
  }

  .card_banner {
    height: auto;
  }

  .close-menu,
  .menu-title {
    display: none;
  }

  .order-review-data ul,
  .order-details-area ul {
    float: left;
    width: 65%;
    height: 100%;
    margin: 0 0 0 20px;
    text-align: left;
  }

  .order-details-area .shipping-info {
    float: left;
    width: 34%;
    height: 100%;
    margin: 0 0 0 1%;
    text-align: left;
  }
}

@media (min-width: 1281px) {
  .order-details-page .actions button {
    width: 205px;
  }

  .order-details-page .totals {
    width: 100%;
  }

  /* .blogpost-page {
        width: 900px;
    } */
  .shopping-cart-page .panel-group-left {
    width: 65%;
  }

  .shopping-cart-page .panel-group-right {
    width: 34%;
  }

  .productAddedToCartOptions-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  .productAddedToCartOptions-group .title-group {
    margin-bottom: 0.5rem;
  }
}
