
.ui-widget.ui-widget-content{
    max-height: 470px;
    overflow-y: overlay;
}
.seach-close-button{
    display: none;
    height: 43px;
    font-size: 13px;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    position: sticky;
    top: 0px;
    color: #acacac;
}
@media (max-width: 576px) {
    .store-search-box input.search-box-text {
        width: calc(100% - 50px) !important;
        margin-left: 0px !important;
        height: 43px !important;
    }
    .store-search-box .search-box-button{
        margin: 0 !important;
        width: 43px !important;
        height: 43px !important;
    }
    .seach-close-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .store-search-box ul.ui-autocomplete{
        overflow: overlay !important;
    }
}