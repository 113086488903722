.sc_card_product_in_cart{
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 10px;
}
.sc_card_product_in_cart:first-child{
    margin-top: 0px;
}
.sc_card_product_in_cart .product-picture-container {
    position: relative;
    width: 100px;
}
.sc_card_product_in_cart .product-picture{
    width: 100px;
}
.sc_card_product_in_cart .product-picture img {
    width: 100px;
    object-fit: cover;
    height: 100px;
}
.sc_card_product_in_cart .product-picture-container > span {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: none;
}
.sc_card_product_in_cart .product-coupon{
    margin-top: 0px;
    width: 100%;
    text-align: left;
}
.sc_card_product_in_cart .sc_item_row{
    padding: 0.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    border-top: 1px solid #f0f0f0;
}
.sc_card_product_in_cart .sc_item_row:first-child{
    border-top: 0px;
}
.sc_card_product_in_cart .product-description{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
.sc_card_product_in_cart .product-info{
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: flex-start;
    margin-left: 1rem;
}
.sc_card_product_in_cart .product-name{
    font-weight: bold;
}
.sc_card_product_in_cart .product-attributes{
    margin-left: 0px !important;
    padding: 0px !important;
    background-color: transparent !important;
}
.sc_card_product_in_cart .sc_input_quantity.default >input{
    margin: 0px !important;
}
.sc_card_product_in_cart .sc_input_quantity.default > button{
    background-color: #fff !important;
    border: 1px solid #e9e9e9 !important;
}
.sc_card_product_in_cart .sc_input_quantity.default > button.inc{
    border-radius: 10px 0px 0px 10px;
    border-right: 0px !important;
}
.sc_card_product_in_cart .sc_input_quantity.default > button.dec{
    border-radius: 0px 10px 10px 0px;
    border-left: 0px !important;
}
.sc_card_product_in_cart .product-action{
    font-size: 13px;
    height: 40px;
    align-items: center;
}
.sc_card_product_in_cart .product-remove{
    color: var(--color-danger);
    cursor: pointer;
}
.sc_card_product_in_cart .product-remove:hover{
    text-decoration: underline;
}
.sc_card_product_in_cart .product-inventory-msg{
    text-align: center;
}
@media (max-width: 576px) {
    .sc_card_product_in_cart{
        font-size: 13px;
    }
    .sc_card_product_in_cart .product-picture, .sc_card_product_in_cart .product-picture-container{
        width: 40px !important;
    }
    .sc_card_product_in_cart .product-picture img {
        width: 40px;
        object-fit: cover;
        height: 40px;
    }
    .sc_card_product_in_cart .sc_item_row{
        align-items: flex-start;
    }
    .sc_card_product_in_cart .product-info{
        height: 70px;
    }
    .sc_card_product_in_cart .sc_input_quantity.default > button{
        width: 25px !important;
        height: 25px !important;
        padding: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sc_card_product_in_cart .sc_input_quantity.default >input{
        height: 25px !important;
        width: 40px !important;
        font-size: 12px !important;
    }
    .sc_card_product_in_cart .product-unit-price{
        font-size: 12px !important;
    }
    .sc_card_product_in_cart .product-unit-price.origin{
        font-size: 11px !important;
        margin-left: 0.5rem !important;
    }
}