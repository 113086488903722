.sc_menu .category-navigation-submenu {
  position: absolute;
  top: 0;
  right: -285px;
  padding: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 8px rgb(0 0 0 / 8%);

}
.sc_menu .category-navigation-submenu .category-navigation-submenu-list {
  position: relative;
  background-color: #fff;
}

@media (min-width: 1281px) {
  .sc_menu
    .category-navigation-submenu
    .category-navigation-submenu-list
    > li
    > a,
  .sc_menu
    .category-navigation-submenu
    .category-navigation-submenu-list
    > li
    > span {
    padding: 13px 25px;
    display: block;
    min-height: 46px;
    text-transform: uppercase;
  }
  .sc_menu .category-navigation-submenu .category-navigation-submenu-list > li {
    position: static;
    border-top: 1px solid #f6f6f6;
  }
  .sc_menu
    .category-navigation-submenu
    .category-navigation-submenu-list
    > li:first-child {
    border-top: 0px;
  }
  .sc_menu .mega-menu-categories.active>a{
      background-color: #f6f6f6;
      color: var(--color-secondary)
  }
}
