.topic-page .page-body {
  overflow: hidden;
}

.topic-page .page-body figure.image>img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}

.topic-page ul {
  list-style: disc;
  margin-left: 1.5rem;
}
