.theme-ajumashop,
.modal {
  --color-primary: #486837;
  --color-secondary: #503a20;
  --color-secondary-lighter: #c49665;
  --color-hotline: #745340;
  --color-danger: #e55353;
  --color-warning: #bf9a02;
  --color-text-bold: #333;
  --color-active: #7bc11e;
  --color-coupon: #cd7000;
  --color-coupon-background: #f9eee0;
  --color-toolbar-admin-background: #879c7f;
  --color-price: #486837;
  --color-primary-bold: #7bc11e;
  --color-address-hover: #f9eee0;
  --color-active-wishlist: #e64f4f;
  --color-toast-success-background: #d4edda;
  --color-toast-error-background: #f8d7da;
  --color-toast-warning-background: #fff3cd;
  --color-slide-button: #c49665;
  --color-secondary-disabled: #77777733;
  --color-border-light-secondary: #e9e9e9;
}

.theme-ajumashop .btn {
  border-radius: 0px;
}

.fb_dialog {
  z-index: 1000 !important;
}

.fb_dialog iframe.fb_customer_chat_icon {
  bottom: 30px !important;
  margin: 0px 5px !important;
}

.mmd-msg>div>.modal {
  z-index: 1070;
}

.mmd-msg>div>.modal-backdrop {
  z-index: 1060;
}

.theme-ajumashop .modal-header {
  font-size: 15px;
}

.theme-ajumashop .spc-categories .product-grid .item-box:hover .buttons-lower button,
.theme-ajumashop .spc-categories .product-grid .item-box button.button-2:hover,
.theme-ajumashop .spc-categories .product-grid .item-box:hover .buttons-lower button span,
.theme-ajumashop .spc-categories .product-grid .item-box:hover .buttons-lower button i,
.theme-ajumashop .spc-categories .product-grid .item-box button.button-2:hover i {
  color: var(--color-secondary);
  background-color: #f9f9f9;
}

.theme-ajumashop .spc-categories .product-grid .item-box .buttons-lower button {
  background-color: #f9f9f9;
}

.theme-ajumashop .spc-categories .product-grid .item-box input.button-2:hover {
  background-color: var(--color-secondary);
}

.theme-ajumashop .product-grid .item-box:hover .buttons-lower button {
  background-color: var(--color-secondary);
}

.theme-ajumashop .product-grid .item-box .buttons-lower button {
  background-color: #fff;
}

.theme-ajumashop .product-grid .item-box:hover .buttons-lower button span,
.theme-ajumashop .product-grid .item-box:hover .buttons-lower button i {
  color: #fff;
  background-color: transparent;
}

.theme-ajumashop .Toastify__toast--success {
  background-color: var(--color-toast-success-background);
  color: var(--color-primary);
  font-weight: 600;
}

.theme-ajumashop .Toastify__close-button--success>svg {
  fill: var(--color-primary);
}

.theme-ajumashop .Toastify__toast--error {
  background-color: var(--color-toast-error-background);
  color: var(--color-danger);
  font-weight: 600;
}

.theme-ajumashop .Toastify__close-button--error>svg {
  fill: var(--color-danger);
}

.theme-ajumashop .Toastify__toast--warning {
  background-color: var(--color-toast-warning-background);
  color: var(--color-warning);
  font-weight: 600;
}

.theme-ajumashop .Toastify__close-button--warning>svg {
  fill: var(--color-warning);
}

.theme-ajumashop .shopping-cart-page .sc_login_type2 .lg>button {
  width: 145px !important;
}

*::-webkit-scrollbar {
  background: transparent;
  width: .4rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-track:hover {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d2d2;
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #c9d1db;
}

/* set button(top and bottom of the scrollbar) */
/* .theme-ajumashop nav::-webkit-scrollbar-button {display:none} */

body {
  max-width: 100%;
  overflow-x: hidden;
  background: #fff;
  font: normal 400 14px "Open Sans", sans-serif;
  color: #777;
  position: inherit;
  overflow: auto;
}

a {
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: var(--color-hotline) !important;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input[type="checkbox"] {
  cursor: default;
  font: 400 13.3333px Arial;
  border-width: 1px;
  border-style: inset;
}

.banner {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0 auto;
}

.banner .banner-left,
.banner .banner-right {
  width: 120px;
  height: 600px;
  background-color: transparent;
  position: absolute;
}

.banner img {
  width: 100%;
}

.banner .banner-right {
  right: 0px;
}

.header {
  position: relative;
  z-index: 1020;
  margin: 0 0 0px;
}

label {
  cursor: default;
}

.header-logo {
  text-align: center;
}

.header-logo a {
  display: inline-block;
  max-width: 100%;
  line-height: 0;
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.store-search-box {
  position: relative;
}

.store-search-box form {
  display: inline-block;
  position: relative;
  font-size: 0;
}

form {
  display: block;
  margin-top: 0em;
}

input,
textarea,
select,
button {
  font-family: "Open Sans";
  font-size: 14px;
}

input {
  font-family: "Open Sans";
  font-size: 14px;
  appearance: auto;
  cursor: text;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
}

input[type="text"i] {
  padding: 1px 2px;
  border-radius: 0;
}

.no-data {
  width: 100%;
  text-align: center;
}

input[type="text"] {
  outline: none;
}

input[type="submit"i] {
  appearance: push-button;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: -internal-light-dark(buttontext, rgb(170, 170, 170));
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74));
  box-sizing: border-box;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
  border-image: initial;
}

.store-search-box .search-box-button {
  display: inline-block;
  width: 47px;
  height: 47px;
  margin: 0 5px;
  border: none;
  background: #f74258 url(/images/ajuma/search-button.png) center no-repeat;
  vertical-align: middle;
  font-size: 0;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.store-search-box .search-box-button {
  background-color: var(--color-secondary);
}

input[type="submit"],
.button-1 {
  cursor: pointer;
  border-radius: 0;
}

.header .center:after {
  content: "";
  display: block;
  clear: both;
}

.footer-upper {
  background-color: var(--color-primary);
}

.newsletter-email {
  display: inline-block;
}

.newsletter-subscribe {
  font-size: 0;
}

.newsletter-email .newsletter-subscribe-text {
  display: inline-block;
  width: 220px;
  height: 43px;
  margin: 0 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.7);
}

.newsletter-email .newsletter-subscribe-button {
  display: inline-block;
  width: 47px;
  height: 47px;
  margin: 0 5px;
  border: none;
  background: var(--color-secondary) url(/images/ajuma/subscribe-button.png) center no-repeat;
  vertical-align: middle;
  font-size: 0;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.social-sharing {
  margin: 22px 0 0;
  /* font-size: 0; */
}

.social-sharing li {
  display: inline-block;
  margin: 0 8px;
}

.footer .center:after {
  content: "";
  display: block;
  clear: both;
}

.social-sharing .facebook a {
  background-position: 0 0;
}

.social-sharing a {
  display: flex;
  width: 32px;
  height: 32px;
  color: #fff;
  align-items: center;
  justify-content: center;
  /* background: url(/images/ajuma/social-sprite-1.png) no-repeat; */
}

.social-sharing a:hover {
  color: #fff !important;
}

.footer-middle {
  overflow: hidden;
  background-color: #f6f6f6;
}

.footer-block .title {
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  padding: 25px;
  font-weight: bold;
  text-transform: uppercase;
}

.footer-lower {
  overflow: hidden;
  background-color: #eee;
  padding: 30px 15px;
}

.accepted-payments {
  margin: 0 0 20px;
  font-size: 0;
}

.accepted-payments .method1 {
  background: url(/images/ajuma/visa.png) no-repeat;
}

.accepted-payments .method2 {
  background: url(/images/ajuma/napas.png) no-repeat;
}

.accepted-payments .method3 {
  background: url(/images/ajuma/master.png) no-repeat;
}

.accepted-payments .method4 {
  background-position: -150px 0;
}

.accepted-payments li {
  display: inline-block;
  width: 50px;
  height: 30px;
  /* background: url(/images/ajuma/payment-sprite.png) no-repeat; */
}

.footer-store-theme {
  margin: 15px 0 0;
}

.store-search-box input.search-box-text {
  display: inline-block;
  width: 220px;
  height: 47px;
  margin: 0 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.7);
}

/*Body*/
.master-column-wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.center-1 {
  margin: 0 0 90px;
}

.home-page-body .center-1 {
  margin: 0;
}

.page {
  min-height: 200px;
  text-align: center;
}

.page:after,
.page-title:after,
.page-body:after {
  content: "";
  display: block;
  clear: both;
}

.spc-categories {
  margin: 0 0 60px;
}

.spc {
  margin: 0 0 20px;
}

.spc-categories .spc-header,
.spc-categories .spc-header.mobile {
  display: none;
}

.spc-header {
  position: relative;
  overflow: hidden;
}

.spc-header ul {
  margin: 0 0 30px;
  padding-right: 1px;
}

.spc-header li {
  border-bottom: 1px solid #ddd;
}

.spc-header li span {
  position: relative;
  display: block;
  padding: 15px 22px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
}

.spc-body {
  position: relative;
  /* min-height: 342px; */
}

.spc-categories .category-info {
  position: relative;
  margin: 0 0 40px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
}

.spc-categories .category-picture {
  display: none;
  font-size: 0;
}

.spc-categories .category-picture a {
  display: block;
  transition: all 0.3s ease;
  height: 100%;
}

.spc-categories .category-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #f0f0f0;
}

.spc-categories .category-products {
  position: relative;
}

.spc-categories .product-grid.active {
  display: block;
}

.spc-body .product-grid.active {
  display: block;
}

.spc-categories .product-grid {
  display: none;
}

.spc-body .product-grid {
  display: none;
}

.spc-body .product-grid {
  margin: 0 !important;
}

.item-grid:after,
.product-grid:after,
.product-list:after,
.manufacturer-grid:after {
  content: "";
  display: block;
  clear: both;
}

/* input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
  border-image: initial;
} */
/* input[type="button" i] {
    appearance: push-button;
    user-select: none;
    white-space: pre;
    align-items: flex-start;
    text-align: center;
    cursor: default;
    color: -internal-light-dark(buttontext, rgb(170, 170, 170));
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74));
    box-sizing: border-box;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
    border-image: initial;
} */

input[type="button"],
input[type="submit"],
button,
.button-1,
.button-2 {
  cursor: pointer;
}

.layout-grid-category {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.layout-grid-category>.item-box.sc_card_search_cat img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.layout-grid-category>.item-box {
  width: 100%;
  height: 100%;
  margin: 0px !important;
}

.layout-grid-category>.item-box>.product-item {
  padding: 0px !important;
}

.layout-grid-category>.item-box .buttons-upper {
  position: relative !important;
  bottom: 0px !important;
}

.layout-grid-category>.item-box .buttons-lower {
  position: relative !important;
  bottom: 0px !important;
}

.item-box {
  position: relative;
  width: 100%;
  float: left;
  margin: 0 0 40px;
}

.spc-categories .product-item {
  padding: 0 !important;
}

.item-box .product-item {
  border: 1px solid #e9e9e9;
  background-color: #fff;
  text-align: center;
}

.spc-categories .quick-view-button {
  display: none !important;
}

.quick-view-button {
  display: inline-block;
  margin: 0 8px;
  vertical-align: middle;
}

.quick-view-button a {
  display: block;
  width: 30px;
  height: 30px;
  background: #888 url(/images/ajuma/quick-view.png) center no-repeat;
  font-size: 0;
  transition: all 0.2s ease 0s;
}

.item-box .picture {
  z-index: 1;
  overflow: hidden;
  margin: 0 0 15px;
}

.item-box .picture a {
  display: block;
  position: relative;
  overflow: hidden;
}

.item-box .picture a:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.item-box .picture img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  margin: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transform: translateZ(0) scale(1, 1);
}

a img {
  opacity: 0.99;
}

a img {
  border: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

.item-box .product-rating-box {
  display: inline-block;
  margin: 0 0 10px;
}

.item-box .rating {
  background: url(/images/ajuma/rating-sprite.png) left top repeat-x;
  width: 80px;
  height: 13px;
}

.item-box .rating div {
  background: #ffc600 url(/images/ajuma/rating-sprite.png) left bottom repeat-x;
  height: 13px;
}

.spc-categories .quick-view-button,
.spc-categories .productQuantityTextBox,
.spc-categories .productQuantityDropdown,
.spc-categories .attribute-squares-wrapper {
  display: none !important;
}

.spc-categories .product-title {
  height: auto;
  margin: 0 0 10px;
}

.item-box .product-title {
  height: 40px;
  overflow: hidden;
  padding: 0 15px;
  font-size: 14px;
  font-weight: normal;
  color: var(--color-text-bold);
}

.item-box .product-title a {
  display: block;
}

.item-box .prices {
  height: 22px;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 0 15px;
}

.item-box .description {
  display: none;
  margin: 0 0 20px;
  line-height: 25px;
}

.item-box .buttons-upper {
  background-color: #f9f9f9;
  font-size: 0;
}

.item-box .buttons-upper input[type="button"] {
  width: 30px;
  height: 30px;
  margin: 0 8px;
  border: none;
  background-color: #888;
  background-image: url(/images/ajuma/product-box-sprite-2.png);
  background-repeat: no-repeat;
  font-size: 0;
  transition: all 0.2s ease;
}

.item-box input.add-to-compare-list-button {
  background-position: 0 center;
}

.item-box input.add-to-wishlist-button {
  background-position: -30px center;
}

.item-box .buttons-lower {
  font-size: 0;
}

.item-box .buttons-lower button {
  display: flex;
  width: 100%;
  height: 45px;
  border: none;
  /* background-color: #fff; */
  font-size: 0;
  transition: all 0.2s ease;
  align-items: center;
  justify-content: center;
}

.ajax-cart-button-wrapper {
  position: relative;
}

.item-box .buttons-lower button span {
  display: inline-block;
  position: relative;
  height: 18px;
  background-color: transparent;
  padding: 0 0 0 10px;
  font-size: 13px;
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.master-wrapper-content {
  position: relative;
  z-index: 0;
}

.item-box .actual-price {
  padding: 0 4px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-price);
}

.spc-header .title {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px;
  font-size: 22px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.spc-header li span:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-secondary);
  opacity: 0;
  transition: all 0.2s ease;
}

.spc-header li.active span:after {
  opacity: 1;
}

.spc-header li span:after {
  background-color: var(--color-secondary);
}

.spc-products .spc-body .loading-overlay {
  bottom: 40px;
}

.spc-body .loading-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
}

.spc-body .loading-overlay:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border-radius: 2px;
  background-color: var(--color-secondary);
  animation: animate 0.5s linear infinite;
}

.spc-body .loading-overlay:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 5px;
  margin: 35px 0 0 -25px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.1;
  animation: shadow 0.5s linear infinite;
}

.product-grid .title {
  margin: 0 0 25px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px;
  font-size: 16px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.home-page .product-grid .title {
  margin: 0 0 20px;
  padding: 0 0 5px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.home-page .spc-header .title {
  font-weight: 600;
  font-size: 18px;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  height: 100%;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.bestsellers .quick-view-button,
.bestsellers .productQuantityTextBox,
.bestsellers .productQuantityDropdown {
  display: none !important;
}

.rich-blog-homepage {
  position: relative;
  margin: 0 0 60px;
}

.rich-blog-homepage .title {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  padding: 0 40px 5px;
  font-size: 18px;
  line-height: 30px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.rich-blog-homepage .owl-carousel {
  position: static;
}

.rich-blog-homepage .owl-prev {
  left: 0;
  background-image: url(/images/ajuma/prev.png);
  background-position: -39px center;
}

.rich-blog-homepage .owl-prev,
.rich-blog-homepage .owl-next {
  position: absolute;
  top: -2px;
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
  font-size: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.rich-blog-homepage .owl-next {
  right: 0;
  background-image: url(/images/ajuma/next.png);
  background-position: -1px center;
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rich-blog-homepage .view-all {
  display: none;
}

.rich-blog-homepage .blog-post {
  margin: 0 0 1px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  text-align: left;
  height: 100%;
}

.rich-blog-homepage .post-picture {
  position: relative;
  z-index: 0;
  font-size: 0;
}

.rich-blog-homepage .post-picture a {
  display: block;
  max-height: 300px;
  overflow: hidden;
}

.rich-blog-homepage .post-picture img {
  width: 100%;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  display: block;
}

.rich-blog-homepage .post-details {
  padding: 25px 30px 29px;
}

.rich-blog-homepage .post-date {
  display: inline-block;
  margin: 0 0 20px;
}

.post-date,
.news-date {
  display: block;
  font-size: 13px;
  font-weight: bold;
  color: #999;
}

.rich-blog-homepage .read-comments {
  display: inline-block;
  position: relative;
  margin: 0 0 0 20px;
  background: url(/images/ajuma/comments.png) left center no-repeat;
  padding: 0 0 0 20px;
  line-height: normal;
  color: var(--color-secondary);
}

.rich-blog-homepage .read-comments:before {
  content: "";
  position: absolute;
  top: 3px;
  left: -10px;
  width: 1px;
  height: 14px;
  background-color: #999;
}

.rich-blog-homepage .post-title {
  display: block;
  margin: 0 0 12px;
  font-size: 18px;
  color: var(--color-text-bold);
}

.post-title,
.news-title {
  display: inline-block;
  margin: 0 0 10px;
  font-size: 25px;
  font-weight: bold;
  color: var(--color-text-bold) !important;
  text-decoration: none !important;
}

.rich-blog-homepage .post-title a {
  display: block;
  height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rich-blog-homepage .post-description {
  height: 112px;
  overflow: hidden;
  line-height: 28px;
}

.rich-blog-homepage .post-description p {
  margin: 0;
  line-height: 28px;
}

.rich-blog-homepage .owl-next:hover {
  background-color: var(--color-secondary);
  background-position: -39px center;
}

.rich-blog-homepage .owl-prev:hover {
  background-color: var(--color-secondary);
  background-position: -1px center;
}

/* .item-box .buttons-lower button span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 18px;
  background: #f74258 url(/images/ajuma/cart-button-1.png) left top no-repeat;
  transition: all 0.2s ease;
} */
/* .item-box .buttons-lower button span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 18px;
  background: #f74258 url(/images/ajuma/cart-button-1.png) left bottom no-repeat;
  transition: all 0.2s ease;
  opacity: 0;
} */
/* .item-box .buttons-lower button span:before,
.item-box .buttons-lower button span:after {
  background-color: var(--color-secondary);
} */
.item-box .buttons-upper input[type="button"]:hover {
  background-color: var(--color-secondary);
}

.breadcrumb {
  margin: 0 0 5px;
  text-align: center;
}

.breadcrumb ul {
  font-size: 0;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li>* {
  display: inline-block;
  min-height: 32px;
  margin: 0 3px;
  font-size: 13px;
  line-height: 32px;
  vertical-align: middle;
}

.breadcrumb li:first-child a {
  width: 20px;
  background: url(/images/ajuma/breadcrumb.png) center no-repeat;
  font-size: 0;
}

.breadcrumb li:first-child>span a {
  display: block;
  width: 20px;
  height: 32px;
  margin: 0 !important;
  background: url(/images/ajuma/breadcrumb.png) center no-repeat;
  font-size: 0;
}

.product-details-page-body .center-1 {
  margin: 0 0 70px;
}

.prev-next-wrapper {
  margin: 20px 0;
  text-align: center;
}

.previous-product,
.next-product {
  display: inline-block;
}

.previous-product a {
  padding: 0 15px 0 27px;
}

.next-product a {
  padding: 0 27px 0 15px;
}

.previous-product a,
.next-product a {
  display: block;
  position: relative;
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.previous-product a:before {
  left: 0;
  background: url(/images/ajuma/product-arrow-left.png) no-repeat right center;
}

.previous-product a:before,
.next-product a:after {
  content: "";
  position: absolute;
  top: 0;
  width: 30px;
  height: 37px;
  transition: all 0.2s ease;
}

.previous-product span:first-child,
.next-product span:first-child {
  display: block;
}

.previous-product span,
.next-product span {
  display: none;
}

.next-product a:after {
  right: 0;
  background: url(/images/ajuma/product-arrow-right.png) no-repeat left center;
}

.product-essential {
  margin: 0 0 20px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 20px;
}

.product-essential:after {
  content: "";
  display: block;
  clear: both;
}

.gallery {
  display: none;
}

.gallery.sevenspikes-cloudzoom-gallery {
  display: block;
}

.gallery .picture-wrapper {
  position: relative;
}

.gallery .picture {
  position: relative;
  width: 320px;
  min-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  font-size: 0;
}

.gallery .picture a {
  display: inline-block;
  max-width: 100%;
}

.gallery .picture img {
  max-width: 100%;
}

.picture-thumbs.in-carousel {
  max-width: 600px;
  margin: 10px auto 0;
  text-align: center;
}

.gallery .picture-thumbs {
  margin: 20px 0 0;
  font-size: 0;
}

.picture-thumbs.in-carousel {
  max-width: 600px;
  margin: 10px auto 0;
  text-align: center;
}

.picture-thumbs.in-carousel .picture-thumbs-list {
  margin: 0;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.overview {
  position: relative;
  margin: 0 0 20px;
}

.overview .product-name {
  /* margin: 0 0 10px; */
  margin: 0 0 0px;
}

.overview .product-name h1 {
  font-size: 25px;
  color: var(--color-text-bold);
}

.overview .prices,
.variant-overview .prices {
  margin: 0 0 10px;
  overflow: hidden;
}

.overview .prices+.short-description {
  margin-top: -5px;
  text-align: justify;
}

.overview .short-description {
  margin: 0 0 20px;
  line-height: 25px;
}

.overview .additional-details,
.variant-overview .additional-details {
  margin: 0 0 30px;
  line-height: 25px;
}

.overview .add-to-cart,
.variant-overview .add-to-cart {
  margin: 0 0 20px;
}

.overview .add-to-cart-panel,
.variant-overview .add-to-cart-panel {
  display: inline-block;
}

.overview .qty-label,
.variant-overview .qty-label {
  display: none !important;
}

.overview .add-to-cart-panel>*,
.variant-overview .add-to-cart-panel>* {
  float: left;
}

label,
label+* {
  vertical-align: middle;
}

.overview .qty-input,
.variant-overview .qty-input,
.overview .qty-dropdown,
.variant-overview .qty-dropdown {
  width: 45px;
  height: 45px;
  margin: 0 5px 0 0;
  text-align: center;
  font-size: 14px;
  color: #777;
}

input[type="text"] {
  height: 36px;
  border: 1px solid #e9e9e9;
  padding: 0 8px;
  vertical-align: middle;
  color: #777;
  border-radius: 0;
}

.ajax-cart-button-wrapper {
  position: relative;
}

.ajax-cart-button-wrapper input[type="button"] {
  vertical-align: middle;
  font-family: inherit;
}

.overview .add-to-cart-button {
  height: 45px;
  border: none;
  /* background: var(--color-secondary) url(/images/ajuma/cart-button-2.png) no-repeat left center; */
  padding: 0 28px 0 28px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
  background-color: var(--color-secondary);
}

.overview .add-to-cart-button span {
  margin-left: 10px;
}

.overview-buttons {
  font-size: 0;
}

.overview-buttons div {
  display: inline-block;
  margin: 0 2px;
}

.overview .add-to-wishlist-button,
.variant-overview .add-to-wishlist-button {
  background-position: 0 center;
}

.overview .add-to-wishlist-button,
.variant-overview .add-to-wishlist-button,
.overview .add-to-compare-list-button,
.overview .email-a-friend-button {
  display: inline-block;
  height: 37px;
  width: 37px;
  border: none;
  background-color: #888;
  background-image: url(/images/ajuma/product-sprite-1.png);
  background-repeat: no-repeat;
  font-size: 0;
  transition: all 0.2s ease;
}

.overview .email-a-friend-button {
  background-position: -74px center;
}

.overview-bottom {
  margin: 40px 0 0;
}

.product-social-buttons {
  margin: 0 0 25px;
}

.product-social-buttons label {
  display: none;
  margin: 0 8px 0 0;
  font-size: 13px;
}

.product-social-buttons ul {
  display: inline-block;
  margin: 0;
}

.social-sharing .twitter a {
  background-position: -32px 0;
}

.product-social-buttons .twitter a {
  background-color: #5dc4e6;
  background-position: -26px 0;
}

.product-social-buttons li a {
  width: 26px;
  height: 24px;
  background: url(/images/ajuma/social-sprite-2.png) no-repeat;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.product-social-buttons .facebook a {
  background-color: #5f7ca7;
  background-position: 0 0;
}

.social-sharing .facebook a {
  background-position: 0 0;
}

.social-sharing .pinterest a {
  background-position: -96px 0;
}

.product-social-buttons .pinterest a {
  background-color: #e56363;
  background-position: -52px 0;
}

.social-sharing .google a {
  background-position: -64px 0;
}

.product-social-buttons .google a {
  background-color: #4f4f4f;
  background-position: -78px 0;
}

.product-reviews-overview {
  margin: 0 0 20px;
  overflow: hidden;
}

.product-review-box {
  display: inline-block;
  margin: 0 0 5px;
}

.product-review-box .rating {
  width: 80px;
  height: 13px;
  background: url(/images/ajuma/rating-sprite.png) left top repeat-x;
}

.product-review-box .rating div {
  height: 13px;
  background: #ffc600 url(/images/ajuma/rating-sprite.png) left bottom repeat-x;
}

.overview .prices>div,
.variant-overview .prices>div {
  margin: 0 0 5px;
}

.overview .product-price,
.variant-overview .product-price {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
}

.overview .tax-shipping-info,
.variant-overview .tax-shipping-info {
  font-size: 13px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.picture-thumbs a.cloudzoom-gallery {
  display: block !important;
  margin: 0 !important;
  float: none !important;
  width: auto !important;
  height: auto !important;
}

.gallery .thumb-item {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 5px 10px;
  overflow: hidden;
  border: 1px solid transparent;
}

.picture-thumbs a.cloudzoom-gallery img {
  border: 1px solid transparent;
  padding: 2px;
  width: 100%;
  max-width: 65px;
  height: 65px;
  object-fit: cover;
  margin: 0px auto;
}

.picture-thumbs a.cloudzoom-gallery.active img {
  border: 1px solid var(--color-primary);
}

.picture-thumbs a.cloudzoom-gallery:before {
  content: "";
  display: block;
  /* padding-top: 100%; */
}

.picture-thumbs .picture-thumbs-item {
  padding: 0 5px;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.product-social-buttons li {
  position: relative;
  z-index: 0;
  margin: 0;
}

.product-selectors {
  margin: 0 0 25px;
  border-bottom: 1px solid #ddd;
  padding: 11px 0;
  text-align: center;
}

.product-selectors:after {
  content: "";
  display: block;
  clear: both;
}

.product-selectors .product-viewmode {
  display: none;
}

.product-selectors>div {
  display: inline-block;
  margin: 5px;
}

.product-selectors span {
  font-size: 13px;
}

select {
  min-width: 50px;
  height: 32px;
  padding: 0 6px;
}

input[type="text"],
input[type="password"],
textarea,
select {
  height: 36px;
  border: 1px solid #e9e9e9;
  padding: 0 8px;
  vertical-align: middle;
  color: #777;
}

.product-selectors select {
  min-width: 65px;
  height: 45px;
  margin: 0 5px;
  padding: 0 10px;
  color: #777;
  text-transform: lowercase;
}

.ajaxBusyPanelParent {
  overflow: hidden;
}

.center-2,
.side-2 {
  margin: 0;
}

.page-title h1 {
  font-size: 22px;
  color: var(--color-text-bold);
}

.page-title {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  padding: 10px 0 10px;
  text-transform: uppercase;
}

.category-description,
.manufacturer-description,
.vendor-description {
  margin: 0 0 30px;
}

.category-description p,
.manufacturer-description p,
.vendor-description p,
.full-description p,
.topic-html-content p,
.topic-page p,
.post-body p,
.news-body p,
.custom-tab p {
  margin: 20px 0;
  line-height: 30px;
}

.category-description p,
.manufacturer-description p,
.vendor-description p {
  margin: 0 0 20px;
}

.filtersPanel {
  background-color: #fff;
}

.filtersPanel:after {
  content: "";
  display: block;
  clear: both;
}

.filter-block:first-child {
  border: none;
}

.filter-block {
  display: block;
  position: relative;
  float: none;
  width: auto;
  margin: 0;
  border-width: 1px 0 0;
}

.block {
  margin: 0 0 10px;
  text-align: center;
}

.block .title {
  border: 1px solid #e9e9e9;
  /* background: #fff url(/images/ajuma/toggle-arrow.png) right center no-repeat; */
  background: #fff;
  padding: 15px 25px;
  font-size: 15px;
  color: var(--color-text-bold);
  text-transform: uppercase;
  cursor: pointer;
}

.filter-block .title {
  position: relative;
  border: none;
  padding: 0;
  color: var(--color-hotline) !important;
}

.filter-block a.toggleControl {
  display: block;
  padding: 15px 25px;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.filter-block a.clearFilterOptions,
.filter-block a.clearPriceRangeFilter {
  position: absolute;
  top: 50%;
  right: 4px;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  background: #fff url(https://ajumagarden.com/Plugins/SevenSpikes.Nop.Plugins.AjaxFilters/Themes/Pavilion/Content/images/clear.png) center no-repeat;
  font-size: 0;
}

.priceRangeFilterPanel7Spikes .filtersGroupPanel {
  padding: 25px 40px;
  width: 95%;
}

.priceRangeMinMaxPanel {
  margin: 0 0 15px;
  overflow: hidden;
}

.priceRangeMinPanel {
  float: left;
}

.priceRangeMaxPanel {
  float: right;
}

.ui-slider {
  position: relative;
  width: auto;
  height: 3px;
  margin: 0 4px 15px;
  background-color: #ececec;
}

.ui-slider-range {
  position: absolute;
  height: 3px;
  background-color: var(--color-secondary);
}

.ui-slider-handle {
  position: absolute;
  top: -5px;
  width: 8px;
  height: 15px;
  margin-left: -4px;
  outline: 2px solid #fff;
  background: var(--color-secondary) url(https://ajumagarden.com/Plugins/SevenSpikes.Nop.Plugins.AjaxFilters/Themes/Pavilion/Content/images/handle.png) bottom no-repeat;
}

.priceRangeCurrentPricesPanel {
  overflow: hidden;
}

.priceRangeCurrentPricesPanel .currentMinPrice {
  float: left;
}

.priceRangeCurrentPricesPanel .currentMaxPrice {
  float: right;
}

.block .listbox {
  display: none;
  margin: -1px 0 0;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 15px 25px;
}

.block .list li {
  padding: 8px 0;
}

.block .product-picture {
  display: none;
}

.block .product-picture img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block .listbox:after {
  content: "";
  display: block;
  clear: both;
}

.block .title strong {
  display: block;
}

.block .tags {
  margin: 0 0 10px;
}

.block .tags ul {
  font-size: 0;
  padding-left: 0px;
}

.block .tags li,
.product-tags-all-page li {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  overflow: hidden;
  font-size: 16px !important;
}

.block .tags li a,
.product-tags-all-page li a {
  float: left;
  /* line-height: 30px; */
  padding: 3px 6px;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  margin-bottom: 5px;
}

.block .tags li a:hover {
  text-decoration: none;
  background-color: var(--color-secondary);
  color: #fff !important;
}

.block .view-all {
  margin: 8px 0 4px;
}

.block .view-all a {
  font-weight: bold;
  color: var(--color-text-bold);
}

.side-2:after {
  content: "";
  display: block;
  clear: both;
}

.infinite-scroll-loader {
  display: none;
  position: relative;
  z-index: 2;
  clear: both;
  height: 100px;
  font-size: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.filtersTitlePanel {
  display: none;
}

.clearFilterOptionsAll {
  float: right;
  cursor: pointer;
}

.product-list .quick-view-button {
  margin: 0 2px;
}

.product-list .quick-view-button a {
  width: 37px;
  height: 37px;
  background-image: url(/images/ajuma/quick-view-list.png);
}

.pager {
  margin: 0 0 20px;
}

.pager ul {
  text-align: center;
  font-size: 0;
}

.pager li {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
}

.pager li>a,
.pager li>span {
  display: block;
  width: 35px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.pager li>span {
  background-color: var(--color-secondary);
  color: #fff;
  cursor: default;
}

.order-progress {
  margin: 0 0 50px;
  text-align: center;
}

.order-progress ul {
  font-size: 0;
}

.order-progress li {
  display: inline-block;
  position: relative;
  margin: 2px;
}

.order-progress li:nth-child(1) {
  z-index: 6;
}

.order-progress a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  border-width: 16px 8px;
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent #f6f6f6;
}

.order-progress a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -16px;
  height: 32px;
  border-width: 16px 8px;
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent #e9e9e9;
}

.order-progress li.active-step a {
  background-color: var(--color-secondary);
  color: #fff;
  cursor: pointer;
}

.order-progress li.active-step a:after {
  border-left-color: var(--color-secondary);
}

.order-progress li:nth-child(2) {
  z-index: 5;
}

.order-progress li:nth-child(3) {
  z-index: 4;
}

.order-progress li:nth-child(4) {
  z-index: 3;
}

.order-progress li:nth-child(5) {
  z-index: 2;
}

.order-progress li:nth-child(6) {
  z-index: 1;
}

.panel-group-left {
  float: left;
  width: 65%;
  background: #fff;
  padding: 0 10px 10px;
}

.fieldset,
.section {
  position: relative;
  margin: 0 0 30px;
}

.fieldset .title,
.section .title {
  background-color: #f9f9f9;
  padding: 25px 30px;
  font-size: 15px;
  color: var(--color-text-bold);
  text-transform: uppercase;
}

.shopping-cart-page .button-1,
.checkout-page .button-1 {
  min-width: 150px;
  border: none;
  background-color: var(--color-secondary);
  padding: 15px 30px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}

.shopping-cart-page .button-1:disabled,
.checkout-page .button-1:disabled {
  background-color: #ebedef;
  cursor: default;
}

.inputs {
  position: relative;
  margin: 0 0 20px;
  white-space: nowrap;
  font-size: 0;
}

.inputs:after {
  content: "";
  display: block;
  clear: both;
}

.inputs label {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  font-size: 13px;
  font-weight: bold;
}

.inputs input[type="text"],
.inputs input[type="password"],
.inputs select,
.inputs textarea {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.panel-group-right {
  float: right;
  width: 34%;
  background: #fff;
  padding: 0 10px 10px;
  margin-left: 5px;
}

.cart,
.data-table,
.compare-products-table,
.forums-table-section table {
  border: 1px solid #e9e9e9;
  background-color: #fff;
}

.cart .quantity .quantity-control {
  display: flex;
  flex-direction: row;
}

.cart .quantity .quantity-control>button {
  border: none;
  background-color: #ebedef;
  padding: 5px;
  color: var(--color-secondary);
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart .quantity .quantity-control .qty-input {
  height: 30px;
}

.cart .quantity .quantity-control>button:active {
  background-color: #d4d5d5;
}

.product-details-page .add-to-cart-panel .quantity-control {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.product-details-page .add-to-cart-panel .quantity-control>button {
  border: none;
  background-color: #ebedef;
  color: var(--color-secondary);
  outline: 1px solid transparent;
  padding: 2px 8px;
  margin-bottom: 1px;
  font-size: 13px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.cart .remove-from-cart,
.cart .add-to-cart,
.data-table .select-boxes,
.data-table .order {
  text-align: center;
}

.cart th,
.data-table th,
.forums-table-section th {
  padding: 20px;
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
}

.footable-first-column {
  text-align: center;
}

.cart .product-picture {
  text-align: center;
}

.cart .product,
.data-table .product,
.data-table .info,
.data-table .name,
.forum-table .forum-details,
.forum-table .topic-details {
  text-align: left;
}

.cart .unit-price {
  white-space: nowrap;
}

.cart .remove-from-cart>div>i,
.cart .remove-from-cart>i {
  width: 30px;
  height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cart .remove-from-cart>div>i:hover,
.cart .remove-from-cart>i:hover {
  background-color: #e56363;
  color: #fff;
}

.cart td,
.data-table td,
.compare-products-table td,
.forums-table-section td {
  min-width: 50px;
  border-top: 1px solid #f0f0f0;
  padding: 15px;
}

.cart tr.n-bdtnp>td {
  border-top: 0px;
  padding-top: 10px !important;
}

.footable.breakpoint>tbody>tr>td>span.footable-toggle {
  position: absolute;
  left: 50%;
  width: 32px;
  height: 32px;
  margin: -5px 0 0 -16px;
  overflow: hidden;
  background-color: #f93b54;
  background-image: url(/images/ajuma/table-sprite.png);
  background-repeat: no-repeat;
}

.footable-toggle {
  background-position: center 0;
}

td.footable-first-column {
  position: relative;
  background: transparent !important;
}

input[type="radio"],
input[type="radio"]+* {
  vertical-align: middle;
}

.cart a,
.data-table a,
.compare-products-table a {
  font-weight: bold;
  color: var(--color-text-bold);
}

.cart .product-picture img {
  width: 60px;
  object-fit: cover;
  height: 60px;
}

.cart .unit-price {
  white-space: nowrap;
  text-align: center;
}

.cart .qty-input,
.cart .qty-dropdown {
  width: 50px;
  height: 40px;
  text-align: center;
}

.cart .subtotal {
  font-weight: bold;
  color: #00a1b1;
}

.common-buttons {
  font-size: 0;
}

.common-buttons .update-cart-button {
  background: #f74258 url(/images/ajuma/cart-sprite.png) left -45px no-repeat;
}

.common-buttons .continue-shopping-button {
  background: #f74258 url(/images/ajuma/cart-sprite.png) left 0 no-repeat;
}

.cart-collaterals {
  margin: 0 0 50px;
}

.cart-footer:after {
  content: "";
  display: block;
  clear: both;
}

.cart-footer .total-info {
  /* width: 300px; */
  max-width: 100%;
  margin: 0px 20px;
}

.cart-total td.cart-total-left {
  text-align: left;
}

.cart-total td {
  width: 50%;
  padding: 5px;
}

.terms-of-service {
  margin: 40px 0 0;
  background-color: #f9f9f9;
  padding: 15px;
  font-size: 13px;
}

.terms-of-service input {
  margin: 0 3px 0 0;
}

.terms-of-service label {
  cursor: pointer;
}

.terms-of-service a {
  margin: 0 0 0 3px;
  color: var(--color-secondary);
  cursor: pointer;
}

.cart-footer .checkout-buttons {
  margin: 40px 0 0;
}

.cart-footer .addon-buttons {
  margin: 40px 0 0;
}

.order-progress a {
  display: block;
  position: relative;
  min-width: 100px;
  height: 32px;
  background-color: #ececec;
  padding: 0 16px 0 24px;
  font-size: 11px;
  line-height: 32px;
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
}

.order-progress li.inactive-step a {
  cursor: default;
}

progress li.active-step a {
  background-color: var(--color-secondary);
}

.shopping-cart-page .button-2,
.checkout-page .button-2 {
  width: 300px;
  max-width: 100%;
  height: 45px;
  margin: 0 0 10px;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
  outline: 2px solid transparent;
  transition: all 0.2s ease;
}

.common-buttons input:hover {
  outline-color: #fff;
  color: var(--color-secondary);
}

.common-buttons .update-cart-button,
.common-buttons .continue-shopping-button {
  background-color: var(--color-secondary);
}

.cart-footer .totals {
  margin: 0;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 40px 0 0;
}

.cart-total td.cart-total-right {
  text-align: right;
}

.cart-total .order-total {
  font-size: 18px;
  font-weight: bold;
  color: #00a1b1;
  text-transform: uppercase;
}

.cart .subtotal,
.mini-shopping-cart .price strong,
.mini-shopping-cart .totals strong,
.item-box .actual-price,
.overview .product-price,
.variant-overview .product-price,
.compare-products-table .product-price td,
.cart-total .order-total,
.productAddedToCartWindowDescription .price {
  color: var(--color-price);
}

.topic-page .page-body {
  line-height: 30px;
}

.header-links a {
  background-image: url(/images/ajuma/header-sprite-1.png);
  background-repeat: no-repeat;
}

.helper {
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 1000;
  width: 250px;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.helper a:hover {
  color: #fff !important;
}

#goToTop-contact {
  display: block;
  position: relative;
  clear: both;
  color: #fff;
  border: 3px solid var(--color-hotline);
  background: var(--color-hotline);
  text-align: center;
  z-index: 10001;
  font-size: 20;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* #goToTop {
  display: none;
  float: right;
  margin-bottom: 50px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid var(--color-hotline);
  z-index: 10001;
  font-size: 0;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
} */
#goToTop {
  display: none;
  position: fixed;
  float: right;
  right: 30px;
  bottom: 90px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background: rgba(225, 225, 225, 0.75) url(/images/ajuma/scroll-page.png) no-repeat center 48%;
  border-radius: 50%;
  border: 3px solid var(--color-hotline);
  transition: all 0.2s ease;
  cursor: pointer;
  margin-bottom: 50px;
}

.ui-autocomplete,
.ui-autocomplete img {
  display: none;
}

.footer-block ul {
  display: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 10px 0;
}

.footer-block li {
  padding: 10px 0;
}

.mini-shopping-cart .count {
  background-color: #f9f9f9;
  padding: 15px;
  text-align: center;
}

.mini-shopping-cart .count a {
  padding: 0 3px;
  color: var(--color-text-bold);
  font-weight: bold;
}

.required {
  margin: 0 3px 0 0;
  font-size: 12px;
  color: #f74258;
}

.ui-autocomplete a {
  display: block;
  padding: 15px;
  font-size: 13px;
  color: var(--color-text-bold);
  text-align: left;
}

.ui-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #ebedefbf;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.footer-block .footer-collapse {
  display: none;
  transition: all 0.5s ease;
}

.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  border: none;
  border-radius: 0px;
}

.btn-primary:focus,
.btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgb(80 58 32 / 24%) !important;
  color: #fff !important;
  background-color: var(--color-secondary) !important;
}

.blogpost-page .post-body img {
  object-fit: cover;
  width: 100%;
}

.sc_modal_editor .modal-body {
  background: #f1f1f1;
}

.spc-categories .details .prices,
.bestsellers .details .prices,
.product-list .details .prices {
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: fit-content;
}

.product-grid .details .prices {
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.spc-categories .details .prices .discount-price,
.bestsellers .details .prices .discount-price,
.product-grid .details .prices .discount-price,
.product-list .details .prices .discount-price {
  color: var(--color-coupon);
  font-weight: bold;
  font-size: 15px;
  margin-right: 0.5rem;
}

.spc-categories .details .prices .actual-price.origin,
.bestsellers .details .prices .actual-price.origin,
.product-grid .details .prices .actual-price.origin,
.product-list .details .prices .actual-price.origin {
  font-size: 13px;
  text-decoration: line-through;
}

.footer-lower .footer-disclaimer {
  text-align: center;
}

.footer-lower .certificates {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.footer-lower .certificates>a {
  width: 150px;
  height: 60px;
  overflow: hidden;
}

.footer-lower .certificates>a>img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  height: 40px;
  width: 100%;
}

.notification>i {
  color: var(--color-danger);
  margin-right: 0.5rem;
}

.notification>a {
  /* overflow: hidden; */
  white-space: nowrap;
}

.notification>a:hover {
  color: inherit !important;
}

.notification.scroll {
  margin: 0;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: scroll-horizontal 25s linear infinite;
  -webkit-animation: scroll-horizontal 25s linear infinite;
  animation: scroll-horizontal 25s linear infinite;
}

.header-above-fixed {
  overflow: hidden;
  width: 100%;
  background-color: #ebedef;
}

.footer-qr {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #f6f6f6;
}

.footer-qr .qr-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem 0.5rem;
  overflow: hidden;
}

.footer-qr .qr-item .qr-img {
  width: 78px;
  height: 78px;
  overflow: hidden;
}

.footer-qr .qr-item .qr-img>a {
  width: 100%;
  height: 100%;
  ;
  position: relative;
}

.footer-qr .qr-item .qr-img>a>img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.footer-qr .qr-item .qr-title {
  width: 100%;
  text-align: center;
}

@media (min-width: 481px) {
  .newsletter-email input[type="text"] {
    width: 300px;
  }

  .search-box input.search-box-text {
    width: 390px;
  }

  .spc-categories .category-info {
    margin: 0;
  }

  .spc-categories .item-box {
    width: 100% !important;
    margin: -1px 0 0 !important;
  }

  .item-box:nth-child(2n + 1) {
    clear: both;
    margin-left: 0;
  }

  .layout-grid-category {
    grid-template-columns: 1fr 1fr;
  }

  .item-box {
    width: 49%;
    margin-left: 2%;
  }

  .spc-categories .product-item {
    text-align: left;
  }

  .spc-categories .picture {
    float: left;
    width: 40%;
    margin: 0;
    padding: 20px 10px 20px 20px;
  }

  .spc-categories .details {
    float: right;
    width: 60%;
    padding: 20px 20px 20px 10px;
  }

  .spc-categories .details .product-title,
  .spc-categories .details .prices {
    padding: 0;
  }

  .spc-categories .details .prices {
    padding: 0;
  }

  .product-grid .details .prices {
    padding: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .spc-categories .buttons-upper,
  .spc-categories .buttons-lower {
    position: static !important;
    float: left;
    width: auto !important;
    height: auto !important;
    border: none !important;
    background-color: transparent;
  }

  .spc-categories input.button-2,
  .spc-categories button.button-2 {
    width: 36px !important;
    height: 36px !important;
    margin: 0 3px 0 0 !important;
    background-color: #f9f9f9;
  }

  .spc-categories .product-box-add-to-cart-button {
    background-position: right center;
  }

  .spc-categories .product-box-add-to-cart-button span {
    background-color: transparent !important;
    font-size: 0 !important;
  }

  .item-box:nth-child(2n + 1) {
    clear: both;
    margin-left: 0;
  }

  .rich-blog-homepage .post-description {
    height: 85px;
  }

  .spc-categories .product-box-add-to-cart-button span:before,
  .spc-categories .product-box-add-to-cart-button span:after {
    display: none !important;
  }

  .block:nth-of-type(2n + 1) {
    clear: both;
  }

  .block {
    float: left;
    width: 100%;
    margin: 0 0 2%;
    text-align: left;
  }

  .block .tags li,
  .product-tags-all-page li {
    margin: 0 5px 0 0;
  }

  .product-essential {
    padding: 30px;
  }

  .overview .add-to-cart,
  .variant-overview .add-to-cart {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .overview-buttons,
  .variant-overview .add-to-wishlist {
    display: inline-block;
    margin: 0;
    padding: 4px 0;
    vertical-align: top;
  }

  .inputs input[type="text"],
  .inputs input[type="password"],
  .inputs select {
    height: 40px;
    padding: 0 12px;
  }

  /* .cart .product-picture img {
        max-width: none;
    } */
  .common-buttons .update-cart-button {
    float: left;
  }

  .common-buttons .update-cart-button,
  .common-buttons .continue-shopping-button {
    width: auto;
    max-width: 50%;
    margin: 0;
    padding: 0 28px 0 48px;
  }

  .common-buttons .continue-shopping-button {
    float: right;
  }

  .common-buttons:after {
    content: "";
    display: block;
    clear: both;
  }

  .ui-autocomplete {
    position: absolute;
    z-index: 1070;
    width: 390px;
    max-width: 100%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    text-align: left;
    border: 0px;
  }

  /* .ui-autocomplete a {
    display: block;
    padding: 15px;
    font-size: 13px;
    color: var(--color-text-bold);
  } */
  .ui-autocomplete img {
    display: none;
    margin-right: 5px;
    vertical-align: middle;
  }

  .ui-autocomplete a:hover {
    background: #f9f9f9;
  }

  .ui-autocomplete li {
    border-top: 1px solid #f6f6f6;
  }
}

@media (min-width: 681px) {
  .spc-categories .spc-body {
    border: 1px solid #e9e9e9;
    background-color: #fff;
  }

  .spc-categories .category-info {
    border: none;
  }

  /* .spc-categories .category-picture {
        max-width: 70%;
        margin: 0 auto 40px;
    } */
  .spc-categories .item-box {
    width: 50% !important;
    clear: none !important;
    margin: 0 0 -1px !important;
  }

  .spc-categories .item-box:nth-child(odd) .product-item {
    border-left-color: transparent !important;
  }

  .spc-categories .product-item {
    border-color: #f0f0f0;
  }

  .spc-categories .product-title {
    height: 20px;
    margin: 0 0 8px;
  }

  .spc-categories .product-title a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .spc-categories .details .prices {
    margin: 0 0 16px;
  }

  .spc-header li {
    display: inline-block;
  }
}

@media (min-width: 769px) {
  .layout-grid-category {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .item-box:nth-child(2n + 1) {
    clear: none;
    margin-left: 2%;
  }

  .item-box:nth-child(3n + 1) {
    clear: both;
    margin-left: 0;
  }

  .item-box {
    width: 32%;
  }

  .bestsellers {
    display: block;
    position: relative;
    margin: 0 0 60px;
  }

  .bestsellers .item-grid {
    position: static;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    background-color: #fff;
  }

  .bestsellers .owl-stage {
    background-color: #fff;
  }

  .bestsellers .owl-item {
    float: left;
    width: 50%;
  }

  .bestsellers .item-box {
    width: 100% !important;
    margin: 0 !important;
  }

  .bestsellers .product-item {
    margin: 0 0 0 -1px;
    border-width: 0 1px 1px;
    border-color: #f0f0f0;
    padding: 0 !important;
    text-align: left;
  }

  .bestsellers .product-item:after {
    content: "";
    display: block;
    clear: both;
  }

  .bestsellers .picture {
    float: left;
    width: 40%;
    margin: 0;
    padding: 20px 10px 20px 20px;
  }

  .bestsellers .details {
    float: right;
    width: 60%;
    padding: 20px 20px 20px 10px;
  }

  .bestsellers .product-title {
    height: 20px;
    margin: 0 0 8px;
    padding: 0;
  }

  .bestsellers .details .prices {
    margin: 0 0 16px;
    padding: 0;
  }

  .bestsellers .buttons-upper,
  .bestsellers .buttons-lower {
    position: static !important;
    float: left;
    width: auto !important;
    height: auto !important;
    border: none !important;
    background-color: transparent;
  }

  .bestsellers input.button-2,
  .bestsellers button.button-2 {
    width: 36px !important;
    height: 36px !important;
    margin: 0 3px 0 0 !important;
    background-color: #f6f6f6 !important;
    /* background-image: url(/images/ajuma/product-box-sprite-big.png) !important; */
  }

  .bestsellers .add-to-wishlist-button {
    background-position: -36px center !important;
  }

  .bestsellers .product-box-add-to-cart-button {
    background-position: right center;
  }

  .bestsellers .product-box-add-to-cart-button span {
    background-color: transparent !important;
    font-size: 0 !important;
    padding: 0px !important;
  }

  .bestsellers .owl-prev {
    left: 0;
    background-image: url(/images/ajuma/arrow-prev.png);
    background-position: right center;
  }

  .bestsellers .owl-prev,
  .bestsellers .owl-next {
    position: absolute;
    top: -2px;
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    font-size: 0;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .bestsellers .owl-next {
    right: 0;
    background-image: url(/images/ajuma/arrow-next.png);
    background-position: left center;
  }

  .rich-blog-homepage .blog-post:after {
    content: "";
    display: block;
    clear: both;
  }

  .rich-blog-homepage .post-picture {
    float: left;
    width: 50%;
  }

  .rich-blog-homepage .post-details {
    float: right;
    width: 50%;
  }

  .rich-blog-homepage .post-description {
    height: 148px;
  }

  .bestsellers .owl-prev:hover {
    background-color: #f74258;
    background-position: left center;
  }

  .bestsellers .owl-next:hover {
    background-color: #f74258;
    background-position: right center;
  }

  .bestsellers .product-box-add-to-cart-button span:before,
  .bestsellers .product-box-add-to-cart-button span:after {
    display: none !important;
  }

  .bestsellers .product-title a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-selectors {
    text-align: left;
  }

  .product-selectors .product-viewmode {
    display: inline-block;
  }

  .product-selectors>div {
    margin: 0 20px 0 0;
  }

  .product-selectors .product-viewmode span {
    display: none;
    vertical-align: middle;
  }

  .product-selectors .product-viewmode a.selected {
    background-color: var(--color-secondary);
  }

  .product-selectors .product-viewmode a {
    display: inline-block;
    width: 18px;
    height: 16px;
    margin: 0 8px 0 0;
    background-color: #777777b3;
    background-image: url(/images/ajuma/viewmode-sprite.png);
    background-position: center top;
    background-repeat: no-repeat;
    vertical-align: middle;
    font-size: 0;
  }

  .product-selectors .product-viewmode a.grid {
    background-position: 0 center;
  }

  .product-selectors .product-viewmode a.list {
    background-position: -18px center;
  }

  .product-selectors .product-page-size {
    float: right;
    margin: 0;
  }

  .block:nth-of-type(2n + 1) {
    clear: none;
  }

  .block:nth-of-type(3n + 1) {
    clear: both;
  }

  /* .block {
        width: 31.3333%;
        margin: 0 1% 2%;
    } */
  .product-essential {
    padding: 30px 20px;
  }

  .gallery {
    float: left;
    width: 48%;
    margin: 0 0 -10px;
  }

  .gallery .picture {
    width: auto;
  }

  .gallery .picture-thumbs {
    text-align: left;
  }

  .overview {
    float: right;
    width: 49%;
    margin: 0 0 -10px;
    text-align: left;
  }

  .overview-bottom {
    clear: both;
    margin: 0;
  }

  .overview-bottom:after {
    content: "";
    display: block;
    clear: both;
  }

  .overview .prices>div,
  .variant-overview .prices>div {
    display: inline-block;
    margin: 0 5px 5px 0;
  }

  .product-social-buttons {
    position: relative;
    bottom: -30px;
    float: left;
    height: 54px;
    margin: 0;
    padding: 10px 0 0;
  }

  .product-social-buttons label {
    display: inline-block;
  }

  .product-social-buttons label,
  .product-social-buttons ul {
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
  }

  .product-reviews-overview>div {
    float: left;
  }

  .product-review-box {
    margin: 3px 12px 0 0;
  }

  .gallery .thumb-item {
    width: 18%;
    margin: 0 1% 10px;
  }

  .product-list .product-item:after {
    content: "";
    display: block;
    clear: both;
  }

  .product-list .item-box .picture {
    float: left;
    width: 280px;
    margin: 0;
  }

  .product-list .item-box .details {
    margin: 0 0 0 280px;
    padding: 30px 20px;
    text-align: left;
  }

  .product-list .item-box .product-title {
    height: auto;
    margin: 0 0 10px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
  }

  .product-list .item-box .prices,
  .product-list .item-box .prices span {
    padding: 0;
  }

  .product-list .item-box .description {
    display: block;
  }

  .product-list .item-box .buttons-upper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 4px 0;
    background-color: transparent;
  }

  .product-list .item-box .buttons-upper input[type="button"] {
    width: 36px;
    height: 36px;
    margin: 0 2px;
    background-image: url(/images/ajuma/product-sprite-1.png);
  }

  .product-list .item-box input.add-to-compare-list-button {
    background-position: -37px 0;
  }

  .product-list .item-box input.add-to-wishlist-button {
    background-position: 0 0;
  }

  .product-list .item-box .buttons-lower {
    float: left;
    margin: 0 5px 0 0;
  }

  .product-list .item-box .buttons-lower button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    outline: 1px solid transparent;
    /* background: #f74258 url(/images/ajuma/cart-button-2.png) left center
      no-repeat; */
    padding: 0 28px 0 28px;
    vertical-align: middle;
  }

  .bestsellers .owl-prev:hover,
  .bestsellers .owl-next:hover,
  .product-selectors .product-viewmode a.selected,
  .product-list .item-box .buttons-lower button,
  .topic-post .post-actions .manage-post a:hover {
    background-color: var(--color-secondary);
  }

  .product-list .item-box {
    float: none;
    width: auto;
    margin: 0 0 40px;
  }

  /* .product-list .item-box .buttons-lower button span:before,
  .product-list .item-box .buttons-lower button span:after {
    display: none;
  } */
  .product-list .item-box .buttons-lower button span,
  .product-list .item-box .buttons-lower button i {
    background-color: transparent;
    padding: 0;
    color: #fff;
  }

  .product-list .item-box .buttons-lower button span {
    margin-left: 10px;
  }

  .cart-options {
    text-align: right;
    margin-top: 10px;
  }

  .common-buttons .update-cart-button,
  .common-buttons .continue-shopping-button {
    max-width: none;
    min-width: 230px;
  }

  .cart-footer .cart-collaterals {
    float: left;
    width: 52%;
  }

  .cart-footer .totals {
    float: right;
    width: 100%;
  }
}

@media (min-width: 1281px) {
  .header .center {
    width: 100%;
    margin: auto;
  }

  .header-upper {
    height: 42px;
    background-color: #f6f6f6;
  }

  .header-links-wrapper {
    position: relative;
    float: right;
    height: 42px;
    margin: 0 0 0 20px;
    transition: all 0.2s ease;
  }

  .header-links-wrapper label {
    background-position: left 0;
  }

  .header-middle {
    background-color: #fff;
    padding: 20px 0;
  }

  .header-middle .center {
    display: table;
  }

  .header-logo {
    display: table-cell;
    width: 20%;
    padding: 0 24px 0 0;
    vertical-align: middle;
  }

  .header-menu-parent {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }

  /* .menu-title, .close-menu {
        display: none;
    } */
  .header-menu-parent {
    display: table-cell;
    vertical-align: middle;
  }

  .category-navigation-title {
    position: absolute;
    bottom: -9px;
    left: 0;
    z-index: 2;
    width: 245px;
    height: 55px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 0 20px;
    font-size: 15px;
    line-height: 53px;
    font-weight: bold;
    color: var(--color-text-bold);
    text-transform: uppercase;
    cursor: pointer;
  }

  .category-navigation-title:after {
    content: "";
    position: absolute;
    top: 19px;
    right: 19px;
    width: 17px;
    height: 15px;
    background: var(--color-secondary) url(/images/ajuma/category-nav.png) center no-repeat;
  }

  .header-lower {
    background-color: var(--color-primary);
    padding: 9px 0;
  }

  .category-navigation-list-wrapper {
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 1;
    width: 245px;
  }

  .category-navigation-list.sticky-flyout {
    position: relative;
  }

  .category-navigation-list.sticky-flyout>li {
    position: static;
  }

  .category-navigation-list>li {
    position: relative;
    border-top: 1px solid #f6f6f6;
  }

  .category-navigation-list>li:first-child {
    border: none;
  }

  .category-navigation-list>li>a,
  .category-navigation-list>li>span {
    display: block;
    min-height: 46px;
    padding: 13px 20px;
    text-transform: uppercase;
  }

  .category-navigation-list {
    display: none;
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }

  .store-search-box {
    float: right;
    clear: right;
    margin: 0;
  }

  .store-search-box .search-box-button {
    margin: 0 0 0 5px;
  }

  .flyout-cart-wrapper {
    position: relative;
    display: table-cell;
    width: 190px;
    padding: 0 0 0 32px;
    vertical-align: middle;
    text-align: right;
    /* float: right; */
  }

  .cart-trigger {
    display: inline-block;
    position: relative;
    height: 52px;
    border: 1px solid #f0f0f0;
    padding: 0 19px 0 18px;
    line-height: 52px;
    font-size: 13px;
    color: #777;
    white-space: nowrap;
    transition: all 0.2s ease;
  }

  .cart-trigger:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -32px;
    width: 32px;
    height: 52px;
    background: var(--color-secondary) url(/images/ajuma/flyout-cart.png) center no-repeat;
  }

  .cart-trigger .cart-qty,
  .cart-trigger .cart-label,
  .cart-trigger .cart-ttl {
    font-weight: bold;
    color: #555;
    transition: all 0.2s ease;
  }

  /*Footer*/
  .footer {
    text-align: left;
  }

  .footer-upper {
    padding: 15px 0;
  }

  .footer .center {
    width: 980px;
    margin: auto;
  }

  .newsletter {
    float: left;
  }

  .newsletter .title {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
    line-height: 28px;
    color: #fff;
  }

  .newsletter-subscribe {
    display: inline-block;
    vertical-align: middle;
  }

  .newsletter-subscribe {
    font-size: 0;
  }

  .social-sharing {
    float: right;
    margin: 0;
    line-height: 47px;
  }

  .social-sharing li {
    vertical-align: middle;
  }

  .footer-middle {
    padding: 60px 0 30px;
  }

  .footer .center:after {
    content: "";
    display: block;
    clear: both;
  }

  .footer-block {
    float: left;
    width: 25%;
  }

  .footer-block .title {
    margin: 0 0 12px;
    border: none;
    padding: 0;
  }

  .footer-lower {
    background-color: #f6f6f6;
    padding: 0;
    text-align: center;
  }

  .footer-lower .center {
    border-top: 1px solid #e9e9e9;
    padding: 30px 0;
  }

  /*Body*/
  .master-wrapper-content {
    /* width: 980px; */
    min-height: 300px;
    margin: auto;
  }

  .page {
    clear: both;
    text-align: left;
  }

  .spc-categories .spc-header {
    display: block;
  }

  .spc-categories .spc-header ul {
    float: right;
    margin: 0;
  }

  .spc-header li {
    display: inline-block;
    border: none;
  }

  .spc-categories .spc-header li.active span {
    color: #fff;
    background-color: var(--color-secondary);
  }

  .spc-header li span {
    padding: 9px 22px;
  }

  .spc-categories .category-info {
    position: relative;
    z-index: 1;
    float: left;
    width: 35%;
    min-height: 342px;
  }

  .spc-categories .category-title {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
    font-weight: bold;
    /* text-transform: uppercase; */
    transition: all 0.3s ease;
  }

  .spc-categories .category-title a {
    display: block;
    padding: 15px 30px;
  }

  .spc-categories .category-picture {
    display: block;
    max-width: none;
    height: 100%;
    margin: 0;
    overflow: hidden;
  }

  .spc-categories .category-products {
    float: right;
    width: 65%;
  }

  .spc-categories .product-grid {
    min-height: 342px;
    overflow: hidden;
  }

  .center-1 .product-grid .item-box:nth-child(3n + 1) {
    clear: none;
    margin-left: 2%;
  }

  .spc-categories .item-box:nth-child(1) .product-item {
    border-top-color: transparent !important;
  }

  .spc-categories .product-grid .product-item {
    height: 172px;
  }

  .product-grid .item-box .product-item {
    padding: 0 0 45px;
  }

  .product-grid .item-box .buttons-upper {
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 30px;
    /* border-right: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9; */
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-grid .item-box .buttons-lower {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 45px;
    border-top: 1px solid #f0f0f0;
    /* border-width: 1px;
    border-style: solid;
    border-color: #f0f0f0 transparent transparent; */
    background-color: #fff;
    transition: all 0.2s ease;
    bottom: -29px;
  }

  .spc-categories .spc-body:after {
    content: "";
    display: block;
    clear: both;
  }

  .spc-categories .product-grid .item-box input.button-2:hover {
    background-color: var(--color-secondary);
  }

  .spc-products .spc-header .title {
    min-height: 36px;
  }

  .spc-products .spc-header ul {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }

  .spc-header li span:after {
    bottom: 0;
  }

  .product-grid .item-box:hover .buttons-lower button,
  .spc-categories .product-grid .item-box button.button-2:hover,
  .product-grid .item-box:hover .buttons-lower button span {
    background-color: var(--color-secondary);
  }

  .product-grid .item-box:hover .buttons-lower button span:before {
    background-color: var(--color-secondary);
    opacity: 0;
  }

  .product-grid .item-box:hover .buttons-lower button span {
    color: #fff;
  }

  .homepage-middle-wrapper:after {
    content: "";
    display: block;
    clear: both;
  }

  .bestsellers {
    float: left;
    width: 66%;
  }

  .bestsellers .product-item {
    height: 170px;
  }

  .bestsellers .owl-prev {
    right: 37px;
    left: auto;
  }

  .bestsellers+.rich-blog-homepage {
    float: right;
    width: 32%;
  }

  .rich-blog-homepage .title {
    padding: 0 0 5px;
  }

  .rich-blog-homepage .owl-prev {
    right: 37px;
    left: auto;
  }

  .rich-blog-homepage .post-picture {
    float: none !important;
    width: auto;
  }

  .rich-blog-homepage .post-picture a {
    max-height: 270px;
  }

  .rich-blog-homepage .post-details {
    float: none !important;
    width: auto;
  }

  .product-grid .item-box:hover .buttons-lower {
    bottom: -29px;
    z-index: 0;
  }

  .product-grid .item-box:hover .buttons-lower button span:after {
    opacity: 1;
  }

  .product-grid .item-box:hover .buttons-lower button span:before {
    opacity: 0;
  }

  .center-2 {
    float: right;
    width: 715px;
    margin: 0 0 90px;
  }

  .center-2 .page .page-title {
    display: none;
  }

  .side-2 {
    float: left;
    width: 245px;
  }

  .side-2 .page-title {
    margin: 0 0 15px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 20px 25px;
  }

  .side-2 .page-title h1 {
    font-size: 18px;
    color: var(--color-secondary);
    text-transform: uppercase;
  }

  .nopAjaxFilters7Spikes {
    margin: -15px 0 0;
  }

  .filtersPanel {
    border: 1px solid #e9e9e9;
  }

  .block:first-child,
  .page-title+.block {
    border-top-color: #e9e9e9;
  }

  .block {
    float: none;
    width: auto;
    margin: -1px 0 0;
    border-width: 1px;
    border-style: solid;
    border-color: #f0f0f0 #e9e9e9 #e9e9e9;
  }

  .block .title {
    border: none;
    background-image: none;
    cursor: default;
  }

  .filter-block .title {
    /* background-image: url(/images/ajuma/toggle-arrow.png); */
  }

  .filter-block a.toggleControl {
    padding: 20px 25px;
  }

  .filter-block .filtersGroupPanel {
    margin: -5px 0 0;
  }

  .block-recently-viewed-products {
    font-size: 0;
  }

  .block .listbox {
    display: block;
    margin: -5px 0 0;
    border: none;
    padding-top: 0 !important;
  }

  .block-recently-viewed-products .product-picture {
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
    vertical-align: middle;
  }

  .block-recently-viewed-products .product-name {
    display: inline-block;
    width: 75%;
    padding: 0 0 0 15px;
    vertical-align: middle;
    font-size: 14px;
  }

  .block .view-all {
    margin: 12px 0 4px;
  }

  .breadcrumb {
    margin-right: 150px;
    margin-top: -10px;
    text-align: left;
  }

  .breadcrumb li>* {
    margin-left: 0;
  }

  .product-details-page-body .center-1 {
    margin: 0 0 90px;
  }

  .prev-next-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    font-size: 0;
  }

  .product-essential {
    padding: 30px;
  }

  .gallery {
    width: 44%;
  }

  .overview {
    width: 54%;
  }

  .product-social-buttons {
    bottom: -35px;
  }

  .social-sharing {
    float: right;
    margin: 0;
    line-height: 47px;
  }

  .social-sharing li {
    vertical-align: middle;
  }

  .gallery .thumb-item {
    width: 23%;
    height: 80px;
    margin: 0 1% 10px;
  }

  .order-progress {
    position: absolute;
    top: 5px;
    right: 0;
    /* background-color: #f6f6f6; */
  }

  .order-progress li:first-child a {
    padding-left: 20px;
  }

  .order-progress li {
    margin: 0 2px;
  }

  .shopping-cart-page .page-title,
  .checkout-page .page-title {
    min-height: 37px;
    padding: 0 0 5px;
  }

  .cart .subtotal {
    white-space: nowrap;
  }

  .cart-footer .cart-collaterals {
    width: 58%;
    margin: 0;
  }

  .cart-footer .totals {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .header-links,
  .header-selectors {
    display: none;
    position: absolute;
    left: -15px;
    z-index: 1020;
    min-width: 150px;
    background-color: #fff;
    border-top: 3px solid #f74258;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }

  .header-links,
  .header-selectors {
    border-top-color: var(--color-secondary);
  }

  .header-links li {
    padding: 0 16px;
  }

  .header-links a {
    display: block;
    padding: 10px 0 10px 24px;
    font-size: 13px;
  }

  .header-links .ico-login,
  .header-links .ico-logout {
    background-position: left -101px;
  }

  /* .header-links-wrapper:hover>div, .header-selectors-wrapper:hover>div, .header-selectors-wrapper>div.active {
        display: block;
    } */
  .flyout-cart-wrapper:hover .cart-qty,
  .flyout-cart-wrapper:hover .cart-label {
    color: #fff;
  }

  .flyout-cart-wrapper:hover .cart-trigger {
    border-color: var(--color-secondary);
    color: #fff;
  }

  .flyout-cart-wrapper:hover .cart-trigger .cart-ttl {
    color: #fff;
  }

  .flyout-cart-wrapper:hover .cart-trigger {
    background-color: var(--color-secondary);
  }

  .ui-autocomplete {
    z-index: 1;
    border: 0px;
    border-radius: 0px;
  }

  .footer-block ul {
    display: block !important;
    border: none;
    background-color: transparent;
  }

  .footer-block li {
    padding: 6px 0;
  }

  .home_topslide .mui-m_list_horizontal {
    margin-left: 320px;
  }

  /* .mini-shopping-cart .count {
        display: none;
    } */
}

@media (min-width: 1281px) {
  .responsive-nav-wrapper-parent {
    display: none;
  }

  .header .center {
    width: 1200px;
    position: relative;
  }

  .header-links-wrapper {
    margin: 0 0 0 30px;
  }

  .header-links-wrapper label {
    display: block;
    padding: 0 0 0 20px;
    background-image: url(/images/ajuma/header-sprite-2.png);
    background-repeat: no-repeat;
    line-height: 42px;
  }

  .header-logo {
    padding: 0 32px 0 0;
  }

  .category-navigation-title {
    width: 285px;
    padding: 0 24px;
  }

  .category-navigation-list-wrapper {
    width: 285px;
  }

  /*Footer*/
  .footer .center {
    width: 1200px;
  }

  /*Body*/
  .master-wrapper-content {
    width: 1200px;
  }

  .center-1 .product-grid .item-box:nth-child(4n + 1) {
    clear: none;
    margin-left: 2%;
  }

  .center-1 .product-grid .item-box:nth-child(5n + 1) {
    clear: both;
    margin-left: 0;
  }

  .center-1 .product-grid .item-box {
    width: 18.4%;
  }

  .spc-categories .picture {
    width: 37%;
  }

  .spc-categories .details {
    width: 63%;
  }

  .rich-blog-homepage .post-picture {
    min-height: 265px;
  }

  .rich-blog-homepage .post-description {
    height: 112px;
  }

  .center-2 {
    width: 890px;
  }

  .side-2 {
    width: 285px;
  }

  .side-2 .page-title {
    padding: 22px 35px;
  }

  .filter-block a.toggleControl {
    padding: 25px 35px;
  }

  .priceRangeFilterPanel7Spikes .filtersGroupPanel {
    padding: 20px 50px;
  }

  .filter-block .filtersGroupPanel {
    margin: -10px 0 0;
  }

  .block .listbox {
    margin: -10px 0 0;
    padding: 22px 35px;
  }

  .block .view-all {
    margin: 12px 0 0;
  }

  .gallery {
    width: 38%;
  }

  .overview {
    width: 60%;
  }

  .product-social-buttons {
    bottom: -40px;
  }

  .category-navigation-list>li>a,
  .category-navigation-list>li>span {
    padding: 13px 25px;
  }

  .cart col:first-child {
    display: none !important;
  }

  .cart-footer .cart-collaterals {
    width: 63%;
  }

  .cart-footer .totals {
    width: 100%;
  }

  .header-links,
  .header-selectors {
    left: 0;
  }

  .owl-carousel .owl-stage-outer {
    /* max-height: 510px; */
    min-height: 510px;
    /* height: 510px; */
  }
}

@media (max-width: 1280px) {
  .home_topslide .mui-m_list_horizontal {
    margin: 0px 0px 25px 0px;
  }

  .header .header-logo {
    margin-top: 53px;
    z-index: 1020;
    width: 100%;
    height: 110px;
    background-color: #fff;
    line-height: 110px;
  }

  .header-upper {
    display: none;
  }

  .category-navigation-title,
  .category-navigation-list-wrapper {
    display: none !important;
  }

  .flyout-cart-wrapper,
  .search-box.store-search-box {
    display: none;
  }

  .responsive-nav-wrapper-parent {
    position: fixed;
    top: 0;
    z-index: 1070;
    width: 100%;
    height: 53px !important;
    border-top: 3px solid #00a1b1;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .responsive-nav-wrapper-parent {
    border-top-color: var(--color-primary);
  }

  .responsive-nav-wrapper {
    position: relative;
    text-align: center;
    font-size: 0;
  }

  .responsive-nav-wrapper>div:first-child {
    border-left: 1px solid #eee;
  }

  .responsive-nav-wrapper div.menu-title {
    background-position: 0 center;
  }

  .responsive-nav-wrapper>div {
    display: inline-block;
    border-right: 1px solid #eee;
    background-color: #f74258;
    background-image: url(/images/ajuma/mobile-sprite.png);
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  .responsive-nav-wrapper>div,
  .plus-button {
    background-color: var(--color-secondary);
  }

  .responsive-nav-wrapper>div>span,
  .responsive-nav-wrapper>div>a {
    display: block;
    width: 52px;
    height: 50px;
    font-size: 0;
  }

  .responsive-nav-wrapper div.shopping-cart-link {
    background-position: -55px center;
    position: relative;
  }

  .responsive-nav-wrapper div.shopping-cart-link .cart-badge {
    position: absolute;
    top: 5px;
    left: 25px;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 10px;
    border-radius: 50px;
    background-color: var(--color-primary-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .responsive-nav-wrapper div.personal-button {
    background-position: -108px center;
  }

  .header-links,
  .header-selectors {
    display: none;
    position: fixed;
    top: 53px;
    left: 0;
    z-index: 1070;
    width: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-size: 13px;
  }

  .header-links li {
    border-top: 1px solid #ececec;
  }

  .header-links .ico-register {
    background-position: left -48px;
  }

  .header-links a {
    display: inline-block;
    padding: 15px 25px;
  }

  .header-links .ico-login,
  .header-links .ico-logout {
    background-position: left -96px;
  }

  .header-links .ico-wishlist {
    background-position: left -192px;
  }

  .responsive-nav-wrapper div.search-wrap {
    background-position: -216px center;
  }

  .overlayOffCanvas {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 1060;
  }

  .overlayOffCanvas.show {
    display: block;
    background: rgba(0, 0, 0, 0.6);
  }

  .flyout-cart-wrapper {
    display: none;
    position: fixed;
    top: 53px;
    left: 0;
    z-index: 1070;
    width: 100%;
    border-top: 1px solid #ececec;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  /* .mini-shopping-cart .items {
        display: none;
        position: relative;
        overflow: hidden;
    } */
  .sc_login .container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sc_login .container>div {
    display: none;
  }

  .sc_login .container>button {
    margin: 5px auto;
    width: 100%;
  }

  .store-search-box {
    display: none;
    position: fixed;
    top: 53px;
    left: 0;
    z-index: 1070;
    width: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #00a1b1;
    padding: 10px;
  }

  .store-search-box {
    background-color: var(--color-primary);
  }

  .store-search-box ul.ui-autocomplete {
    top: 55px !important;
    left: 0px !important;
    width: 100% !important;
    max-height: 80vh;
    overflow: auto;
    background-color: #fff;
  }

  .master-wrapper-content {
    padding: 0px;
  }

  .spc-header li {
    display: inline-block;
  }

  .spc-categories .spc-header.mobile {
    display: block;
    background-color: #ebedef;
  }

  .header-above-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1070;
    /* padding: 0px 10px; */
  }

  .header.has-notify .responsive-nav-wrapper-parent {
    top: 40px;
  }

  .header.has-notify .header-logo {
    margin-top: 93px;
  }

  .header.has-notify .header-links,
  .header.has-notify .header-selectors,
  .header.has-notify .store-search-box,
  .header.has-notify .flyout-cart-wrapper {
    top: 93px;
  }

  .footer-qr {
    background-color: #eee;
  }
  .spc-categories .category-info{
    height: auto !important;
  }
}

@media (max-width: 576px) {

  .spc-categories .product-grid .product-item,
  .product-grid .product-item {
    min-height: 172px;
    height: 100%;
  }

  .spc-categories .picture,
  .product-grid .picture {
    float: left;
    width: 35%;
    margin: 0;
    height: 120px;
    padding: 10px;
  }

  .spc-categories .details,
  .product-grid .details {
    float: right;
    width: 65%;
    padding: 10px 10px 10px 0px;
  }

  .spc-categories .product-item,
  .product-grid .product-item {
    text-align: left;
  }

  .spc-categories .product-title,
  .product-grid .product-title {
    height: 20px;
    margin: 0 0 8px;
  }

  .spc-categories .details .product-title,
  .spc-categories .details .prices,
  .product-grid .details .product-title,
  .product-grid .details .prices {
    padding: 0;
  }

  .spc-categories .product-title a,
  .product-grid .product-title a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .spc-categories .details .prices,
  .product-grid .details .prices {
    margin: 0 0 16px;
    padding: 0;
  }

  .product-grid .item-box .buttons-upper {
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 30px;
    border-right: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-grid .item-box .buttons-lower {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 45px;
    border-width: 1px;
    border-style: solid;
    border-color: #f0f0f0 #e9e9e9 #e9e9e9;
    background-color: #fff;
    transition: all 0.2s ease;
  }

  .product-grid .buttons-upper,
  .product-grid .buttons-lower {
    position: static !important;
    float: left;
    width: auto !important;
    height: auto !important;
    border: none !important;
    background-color: transparent;
  }

  /* .spc-categories .product-box-add-to-cart-button,
  .product-grid .product-box-add-to-cart-button {
    background-position: right center;
  } */

  .product-grid input.button-2,
  .product-grid button.button-2 {
    width: 36px !important;
    height: 36px !important;
    margin: 0 3px 0 0 !important;
    background-color: #f9f9f9 !important;
  }

  .spc-categories .product-box-add-to-cart-button span,
  .product-grid .product-box-add-to-cart-button span {
    background-color: transparent !important;
    font-size: 0 !important;
  }

  .item-box .buttons-lower button span:before {
    background: transparent !important;
  }

  .item-box {
    margin: 0 0 10px;
  }

  .spc-categories .category-info {
    margin: 0;
  }

  .spc-categories .category-info .category-title {
    margin: 0.5rem;
    color: var(--color-secondary);
  }

  .footer .center .accepted-payments {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overview .product-name {
    margin: 10px 0 10px;
  }

  .footer-block .footer-collapse {
    display: block;
  }

  .footer-block .list {
    padding: 25px;
  }

  .page-title {
    text-align: center;
  }

  .side-2 .page-title {
    display: none;
  }

  .category-page .product-selectors {
    background-color: #fff;
  }

  .blogpost-page .post-body {
    overflow: hidden;
  }

  .blogpost-page .post-body img {
    object-fit: scale-down;
    width: 100%;
  }

  .blogpost-page {
    top: 20px !important;
  }

  .blog-page .post {
    margin: 0 0 20px !important;
  }

  .input-range__label-container {
    left: -100%;
  }

  .product-list .item-box .buttons-upper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 4px 0;
  }

  .item-box .buttons-lower button span {
    padding: 0px;
  }

  .spc-header ul {
    margin: 0 0 20px;
  }

  .order-progress {
    margin: 0 0 20px;
  }

  .notification.scroll {
    -moz-animation: scroll-horizontal 25s linear infinite;
    -webkit-animation: scroll-horizontal 25s linear infinite;
    animation: scroll-horizontal 25s linear infinite;
  }
}

@media (max-width: 769px) {
  .product-details-page .sc_tab .productTab_content .tab_content figure {
    width: 100% !important;
  }
}

/* Move it (define the animation) */
/* @-moz-keyframes scroll-horizontal {
  0% { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes scroll-horizontal {
  0% { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
}
@keyframes scroll-horizontal {
  0% { 
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  }
  100% { 
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  }
} */
