.sc_card_product_in_quickorder{
    background-color: #f6f6f6;
    width: 100%;
}
.sc_card_product_in_quickorder .main-content .picture {
  height: 75px;
  min-width: 75px;
  max-width: 75px;
  overflow: hidden;
}
.sc_card_product_in_quickorder .main-content .picture > a,
.sc_card_product_in_quickorder .main-content .picture > a > img {
  width: 100%;
  height: 100%;
}
.sc_card_product_in_quickorder .main-content .picture > a > img {
  object-fit: cover;
}
.sc_card_product_in_quickorder .main-content{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    padding: 10px;

}
.sc_card_product_in_quickorder .sc_input_quantity.default>button {
    background-color: #fff!important;
    border: 1px solid #e9e9e9!important;
    width: 25px!important;
    height: 25px!important;
    padding: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sc_card_product_in_quickorder .sc_input_quantity.default>button.inc {
    border-radius: 10px 0 0 10px;
    border-right: 0!important;
}
.sc_card_product_in_quickorder .sc_input_quantity.default>input {
    margin: 0!important;
    height: 25px!important;
    width: 40px!important;
    font-size: 12px!important;
}
.sc_card_product_in_quickorder .sc_input_quantity.default>button.dec {
    border-radius: 0 10px 10px 0;
    border-left: 0!important;
}
.sc_card_product_in_quickorder .main-content .quantity{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.sc_card_product_in_quickorder .is-order{
    height: 30px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sc_card_product_in_quickorder .is-order>span{
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sc_card_product_in_quickorder .is-order>span.active{
    background-color: var(--color-primary);
    cursor: pointer;
}
.sc_card_product_in_quickorder .icon-tick-bold{
    color: #fff;
    font-size: 8px;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.sc_card_product_in_quickorder .details{
    width: 100%;
    margin-left: 0.5rem;
    color: var(--color-text-bold);
}
.sc_card_product_in_quickorder .details .product-name{
    font-weight: 600;
    height: 40px;
    font-size: 12.7px;
    overflow: hidden;
}
.sc_card_product_in_quickorder .details .product-sku{
    font-size: 12px;
}
.sc_card_product_in_quickorder .details .product-price{
    font-size: 12px;
}
.sc_card_product_in_quickorder .footer{
    margin: 0px !important;
    padding: 10px;
    border-top: 1px solid #e9e9e9;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sc_card_product_in_quickorder .footer .total{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-primary);
    font-weight: 600;
}
.sc_card_product_in_quickorder .note{
    font-size: 12px;
    width: 100%;
    padding: 10px;
    color: var(--info);
    height: 50px;
}
.sc_card_product_in_quickorder .price-discount{
    color: var(--color-coupon);
}
.sc_card_product_in_quickorder .price-actual .has-discount{
    text-decoration: line-through;
    font-size: 11px;
    margin-left: 0.3rem;
}
.sc_card_product_in_quickorder .price-actual{
    display: inline-block;
}
.sc_card_product_in_quickorder .product-coupon{
    width: 100%;
    margin-top: 0 !important;
    background-color: transparent;
}
@media (max-width: 576px) {
    .sc_card_product_in_quickorder .footer{
        flex-direction: row !important;
    }
    .sc_card_product_in_quickorder .price-actual{
        display: block;
    }
    .sc_card_product_in_quickorder .price-actual .has-discount{
        margin-left: 0;
    }
}