.sc_card_optional_order{
    width: 100%;
    margin-top: 2px;
    background-color: #fff;
    padding: 10px;
}
.sc_card_optional_order .item{
    width: 100%;
    background-color: #f6f6f6;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
}
.sc_card_optional_order .item.vertical{
    background-color: #f6f6f6;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.sc_card_optional_order .item>div{
    width: 100%;
}
.sc_card_optional_order .item>div>label{
    font-size: 13px;
    padding: 0 8px;
}
.sc_card_optional_order .item>div:first-child{
    margin-bottom: 10px;
}
.sc_card_optional_order .item.vertical>div:first-child{
    margin-bottom: 0px;
    margin-right: 10px;
}
.sc_card_optional_order .form-control:focus {
    border-color: #c4c9d0;
    box-shadow: none;
}
/* .sc_card_optional_order .item:nth-child(n+1){
    margin-right: 7px;
}
.sc_card_optional_order .item:nth-child(4n+1){
    margin-top: 7px;
}
.sc_card_optional_order .item:first-child{
    margin-top: 0px;
}
.sc_card_optional_order .item.vertical:nth-child(n+1){
    margin-right: 0px;
}
.sc_card_optional_order .item.vertical:nth-child(4n+1){
    margin-top: 0px;
}
.sc_card_optional_order .item.vertical:nth-child(2n+2){
    margin-top: 7px;
    margin-left: 7px;
}
.sc_card_optional_order .item.vertical:nth-child(2n+1){
    margin-top: 7px;
    margin-left: 0px;
}
.sc_card_optional_order .item.vertical:first-child{
    margin-top: 0px;
    margin-left: 0px;
}
.sc_card_optional_order .item.vertical:nth-child(2){
    margin-top: 0px;
} */
.sc_card_optional_order .btn-remove{
    color: var(--color-secondary);
    background-color: #fff;
    border-radius: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 14px;
}
.sc_card_optional_order .btn-remove:focus{
    box-shadow: none;
}
@media (max-width: 576px) {
    .sc_card_optional_order .item.vertical{
        flex-direction: column;
    }
    .sc_card_optional_order .item.vertical>div:first-child{
        margin-bottom: 10px;
        margin-right: 0px;
    }
}