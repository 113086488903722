.card_banner{
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
    min-height: 285px;
    max-height: 400px;
    overflow: hidden;
}
.card_banner img{
    width: 100%;
    object-fit: contain;
}
@media (max-width: 576px){
    .card_banner{
        max-height: 200px;
        min-height: 0px;
        margin: 0px;
    }
    .home_topslide .mui-m_list_horizontal{
        margin: 0px -10px 25px -10px;    
    }
}