.bestsellers .item-box:hover .buttons-lower button i{
    color: var(--color-secondary) !important;
}
.bestsellers .item-box:hover .buttons-lower button.disabled i,
.bestsellers .item-box:hover .buttons-lower button.disabled span{
    color: #88888857 !important;
}
.home_promotion{
    margin-bottom: 20px;
}
.home_promotion .slick-slider .item-box{
    width: 98% !important;
}
.home_promotion .title .text{
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}
.home_promotion .title .text.has-link{
    text-align: left;
}
.home_promotion .slick-prev{
    left: -25px !important;
}
.home_promotion .slick-next{
    right: -20px !important;
}
.home_promotion .title{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
.home_promotion .title .btn-see-more{
    font-weight: 500;
    font-size: 13px;
    text-transform: none;
    max-width: 65px;
    min-width: 65px;
    width: 65px;
    color: var(--color-text-bold);
}
.bestsellers .title{
    margin: 0 0 20px;
    padding: 0 0 5px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    color: var(--color-text-bold);
    text-transform: uppercase;
}
@media (max-width: 576px){
    .bestsellers {
        display: block;
        width: 100%;
    }
    .rich-blog-homepage .blog-posts .owl-stage{
        width: 100% !important;
    }
    .rich-blog-homepage .blog-posts .owl-stage .owl-item{
        width: 100% !important;
    }
    .bestsellers .owl-carousel .owl-item{
        width: calc(100vw - 30px) !important;
    }
    .home-page .product-grid .title, .rich-blog-homepage .title, .spc-header .title, .spc-categories .category-info .category-title{
        font-size: 18px !important;
        font-weight: bold !important;
    }
    .home_promotion .slick-slider .item-box{
        width: 100% !important;
    }
    .home_promotion .slick-prev{
        left: 5px !important;
    }
    .home_promotion .slick-next{
        right: 5px !important;
    }
    .home-page .spc-products .item-grid{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        column-gap: 10px;
        grid-row-gap: 10px;
        row-gap: 10px;
    }
    .home-page .spc-products .item-box{
        height: 100%;
    }
}