 .sc_custom_slide {
   width: 100%;
   position: relative;
 }

 .paginate-control-pre {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .sc_custom_slide .main-list {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: nowrap;
   width: 100%;
 }

 .sc_custom_slide .paginate-control-nxt {
   position: absolute;
   top: 0;
   right: 0;
   height: 100%;
   width: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .sc_custom_slide .btn-pre,
 .sc_custom_slide .btn-nxt {
   background-color: #fff;
   width: 25px;
   height: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50px !important;
   box-shadow: 2.4px 2.4px 3.2px rgb(0 0 0 / 15%)
 }

 .sc_custom_slide .btn-pre:focus,
 .sc_custom_slide .btn-nxt:focus {
   box-shadow: none;
 }
