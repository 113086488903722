.post-body, .news-body {
    margin: 0 0 30px;
    line-height: 30px;
}
.blog-page .post-body {
    margin: 0;
    /* padding: 25px 30px 29px; */
    padding: 10px;
}
.post-date, .news-date {
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: #999;
}
.blog-page .post, .news-items .news-item {
    position: relative;
    margin: 0 0 20px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 30px;
}
.blog-page .post-date {
    display: inline-block;
    margin: 0 0 10px;
}
.blog-posts .read-more, .blog-posts .read-comments {
    display: inline-block;
    line-height: 32px;
}
.blog-posts .read-more, .blog-posts .read-comments {
    padding: 10px 0;
    text-transform: capitalize;
}
.blog-posts .post-head, .blog-posts .read-comments {
    display: none;
}

.post-body a, .news-body a {
    color:var(--color-secondary);
}
.blog-page .read-comments:before {
    content: "";
    position: absolute;
    top: 3px;
    left: -10px;
    width: 1px;
    height: 14px;
    background-color: #999;
}

.post-title, .news-title {
    display: inline-block;
    margin: 0 0 10px;
    font-size: 25px;
    font-weight: bold;
    color: var(--color-text-bold)!important;
    text-decoration: none!important;
}
.blog-page .post-body p {
    margin: 0;
    line-height: 28px;
}
.blog-page .post-title {
    display: block;
    margin: 0 0 10px;
    font-size: 18px;
    color: var(--color-text-bold);
    text-decoration: none!important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-page .rich-blog-image a {
    display: block;
    height: 240px;
    overflow: hidden;
}
.blog-page .rich-blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blog-page .marker-inactive {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0.5em;
    background: var(--color-danger);
    color: white;
}
.blog-page .post-body>.post-preview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-page .post-body>.post-preview>div>h2 {
    font-size: 1em;
}
.blogpost-page .post-body .image{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 1rem;
    width: fit-content;
}
.blogpost-page .post-body td{
    padding: 0px;
    padding-left: .4rem;
    border: 1px solid #d8dbe0;
}
.blogpost-page .post-body figcaption {
    text-align: center;
}
@media (min-width: 481px)
{
    .blog-page .post, .news-items .news-item, .blogpost-page .post, .news-item-page .news-item, .comment-list .comment, .new-comment .form-fields {
        padding: 30px 30px;
    }
}
.blog-page .rich-blog-image {
    position: relative;
    z-index: 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #e9e9e9;
    font-size: 0;
}
.blog-page .post-head {
    display: block;
    margin: 0;
}
@media (min-width: 769px)
{
    .post-head~*, .news-head~* {
        display: block;
    }
    .blog-page .post-body {
        /* float: right;
        width: 50%; */
    }
    .blog-posts .read-more, .blog-posts .read-comments {
        padding: 0;
    }
    .blog-page .post, .news-items .news-item, .blogpost-page .post, .news-item-page .news-item, .comment-list .comment, .new-comment .form-fields {
        padding: 30px 30px;
    }
    .blog-page .rich-blog-image {
        border-width: 0 1px 0 0;
    }
}
.blog-page .read-comments {
    display: inline-block;
    position: relative;
    margin: 0 0 0 20px;
    background: url(/images/ajuma/comments.png) left center no-repeat;
    padding: 0 0 0 20px;
    line-height: normal;
    color:var(--color-secondary);
    text-decoration: none!important;
}
.blog-page .post {
    margin: 0 0 40px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 0;
    text-align: left;
}
@media (min-width: 1281px)
{
    .blog-page .post:nth-child(3n+1) {
        clear: both;
        margin-left: 0;
    }
    .blog-page .first-blog-post .post {
        /* float: none; */
        width: 100%;
        margin-left: 0;
        height: auto;
    }
    .blog-page .post-head, .blog-page .post-body {
        float: none;
        width: auto;
    }
    .blog-page .first-blog-post .post-body {
        float: right;
        width: 50%;
    }
    .blog-page .first-blog-post .post-title {
        margin: 0 0 15px;
        font-size: 22px;
    }
    .blog-page .first-blog-post .post-preview {
        height: auto;
        max-height: 170px;
    }
    .blog-page .post {
        float: left;
        width: 32%;
        margin: 0 0 30px 2%;
        height: 550px;
    }
    .blog-page .rich-blog-image {
        border-width: 0 0 1px 0;
    }
    .blog-page .first-blog-post .post-head {
        float: left;
        width: 50%;
    }
    .blog-page .first-blog-post .rich-blog-image {
        border-width: 0 1px 0 0;
    }
}
@media (min-width: 1281px)
{
    .blog-page .first-blog-post .post-preview {
        max-height: 250px;
    }
}