.sc_product_tag{
    margin: 0 0 60px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
}
.sc_product_tag .title {
    background-color: #f9f9f9;
    padding: 25px 30px;
    font-size: 15px;
    color: var(--color-text-bold);
    text-transform: uppercase;
}
.sc_product_tag .product_taglist {
    padding: 25px 30px;
}
.sc_product_tag .product_taglist .tag{
    display: inline-block;
    font-size: 14px;
    padding: 3px 6px;
    border: 1px solid var(--color-secondary);
    border-radius: 4px;
}
.sc_product_tag .product_taglist .tag:hover{
    text-decoration: none;
    background-color: var(--color-secondary);
    color: #fff !important;
}
.sc_product_tag .product_taglist .separator{
    margin: 0 5px 0 3px;
}