.mini-shopping-cart .item {
    border-bottom: 1px solid #f6f6f6;
    overflow: hidden;
    padding: 20px;
    text-align: left;
}
.mini-shopping-cart .picture {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 0;
}
.mini-shopping-cart .picture a {
    display: block;
    position: relative;
    overflow: hidden;
}
.mini-shopping-cart .picture img {
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 60px;
    max-height: 60px;
    object-fit: cover;
}
.mini-shopping-cart .picture+.product {
    margin: 0 0 0 120px;
}
.mini-shopping-cart .name {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text-bold);
}
.mini-shopping-cart .price strong {
    /* vertical-align: middle; */
    font-weight: bold;
    color: var( --color-primary);
}
.mini-shopping-cart .price label{
    margin-bottom: 0px;
    vertical-align: unset;
}
.mini-shopping-cart .price span{
    vertical-align: unset;
    margin: 0px 0.2rem;
}
.mini-shopping-cart .attribute{
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-primary-bold);
    margin-bottom: 5px;
    cursor: pointer;
}
.mini-shopping-cart .attribute>div>span {
    margin-left: .5rem;
}
.mini-shopping-cart .attribute>div {
    margin-right: .5rem;
}
@media (min-width: 1281px)
{
    .mini-shopping-cart .item {
        padding: 25px 20px;
    }
}