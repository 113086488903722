.quick-order .tab-header-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.quick-order .tab-header-2 .title-grp,
.quick-order .filter-result {
  margin: 2px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.quick-order .tab-header-2 .title-grp:first-child {
  margin-left: 0;
}
.quick-order .tab-header-2 .label,
.quick-order .filter-result .label {
  position: relative;
  display: block;
  padding: 15px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--color-secondary);
  background-color: #f6f6f6;
}
.quick-order .filter-result {
  width: 48px;
  height: 48px;
}
.quick-order .filter-result .label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.quick-order .tab-header-2 .title-grp.active .label,
.quick-order .filter-result.active .label {
  color: #fff;
  background-color: var(--color-secondary);
}
.quick-order .filter-result .number_result_filter {
  color: var(--color-secondary);
  background-color: transparent;
}
.quick-order .filter-result.active .number_result_filter {
  color: #fff;
  background-color: var(--color-secondary);
}
.quick-order .tab-header-2 .avatar-grp-wrapper {
  width: 100%;
  height: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
}
.quick-order .tab-header-2 .avatar-grp {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.quick-order .tab-header-2 .title-grp .avatar-grp-wrapper .overlay-avatar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
.quick-order
  .tab-header-2
  .title-grp.active
  .avatar-grp-wrapper
  .overlay-avatar {
  display: none;
}
.quick-order .title-group-desc {
  display: none;
}
.quick-order .tab-header-2 .title-grp.active .number_result_filter {
  color: #fff;
}
.quick-order .product-sorting > span {
  min-width: 85px;
}
.quick-order .product-sorting select {
  width: 60%;
}
.quick-order .mf-container,
.quick-order .mtf-item {
  margin: 0;
  margin-right: 5px;
  padding: 0;
}
.quick-order .review {
  width: 15%;
}
.sc_quickorder_filter {
  margin-bottom: 20px;
}
.sc_quickorder_filter.is-review {
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 0px;
}
.quick-order .filter-result .number_result_filter {
  position: absolute;
  top: 3px;
  right: 3px;
  color: var(--color-secondary);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.quick-order .filter-result .label > strong {
  display: none;
}
.quick-order .cart-item-row.admin {
  display: none;
}
.quick-order .cart td.admin {
  font-size: 12px;
  width: 350px;
  max-width: 350px;
}
.quick-order .cart th.admin {
  text-align: center;
}
.quick-order .product-unit-price .price-discount {
  color: var(--color-coupon);
}
.quick-order .product-unit-price{
  display: flex;
  align-items: center;
  justify-content: center;
}
.quick-order .product-unit-price .price-actual.has-discount {
  text-decoration: line-through;
  font-size: 12px;
  margin-left: 0.3rem;
}
.quick-order .product-coupon {
  margin-top: 0.2rem;
}
@media (max-width: 1280px) {
  .quick-order .tab-header-2 .label {
    display: none;
  }
  .quick-order .filter-result .label {
    display: block;
  }
  .quick-order .tab-header-2 .avatar-grp-wrapper {
    display: block;
  }
  .quick-order .filter-result .avatar-grp-wrapper {
    display: none;
  }
  .quick-order .tab-header-2 {
    margin-bottom: 20px;
  }
  .quick-order .tab-header-2 .title-grp {
    width: 16%;
    height: 58px;
    padding: 1px;
    border: 1px solid transparent;
  }

  .quick-order .title-group-desc {
    display: block;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: var(--color-secondary);
  }
  .quick-order .tab-header-2 .title-grp .number_result_filter,
  .quick-order .tab-header-2 .title-grp.active .number_result_filter {
    color: var(--color-secondary);
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quick-order .filter-result-container {
    width: 100%;
  }
  .quick-order .tab-header-2 .title-grp.active {
    border: 1px solid #000;
  }
  .quick-order .tab-header-2 .filter-result.active {
    border: 0px;
  }
  .quick-order .filter-result .label {
    margin: 2px;
  }
}
@media (max-width: 769px) {
  .quick-order .tab-header-2 .title-grp {
    width: 19.3%;
    height: 48px;
  }
  .quick-order .tab-header-2 .title-grp:nth-child(5n + 1) {
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .quick-order .tab-header-2 .title-grp {
    width: 23.9%;
    height: 48px;
  }
  .quick-order .tab-header-2 .title-grp:nth-child(5n + 1) {
    margin-left: 2px;
  }
  .quick-order .tab-header-2 .title-grp:nth-child(4n + 1) {
    margin-left: 0;
  }
  /* .quick-order .mf-container, .quick-order .mtf-item{
    margin: 0;
    padding: 0;
  } */
  .quick-order .mf-container {
    margin-bottom: 10px;
  }
  .quick-order .product-sorting {
    width: 100%;
    margin-bottom: 10px;
  }
  .quick-order .product-sorting {
    width: 100%;
  }
  .quick-order .product-sorting select {
    width: 100%;
  }
  .quick-order .review {
    width: 100%;
    margin: 0px;
  }
  .sc_quickorder_filter.is-review {
    padding: 25px 30px;
  }
  .sc_quickorder_filter.is-review .review {
    margin-bottom: 10px;
  }
  .sc_quickorder_filter.is-review .review:last-child {
    margin-bottom: 0px;
  }
  .quick-order .filter-result .label > strong {
    display: block;
    margin-left: 0.5rem;
  }
  .quick-order .filter-result .number_result_filter {
    display: none;
  }
  .quick-order .filter-result {
    width: 100%;
  }
  .quick-order .filter-result .label {
    padding: 10px;
    display: flex;
  }
  .quick-order .cart-item-row.admin {
    border-top: 1px solid #f0f0f0;
    display: table-row;
    font-size: 12px;
  }
  .quick-order .cart td.admin,
  .quick-order .cart th.admin {
    display: none;
  }
  .quick-order .sc_input_quantity.default > button {
    background-color: #fff !important;
    border: 1px solid #e9e9e9 !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quick-order .sc_input_quantity.default > button.inc {
    border-radius: 10px 0 0 10px;
    border-right: 0 !important;
  }
  .quick-order .sc_input_quantity.default > input {
    margin: 0 !important;
    height: 25px !important;
    width: 40px !important;
    font-size: 12px !important;
  }
  .quick-order .sc_input_quantity.default > button.dec {
    border-radius: 0 10px 10px 0;
    border-left: 0 !important;
  }
  .quick-order .product-unit-price{
    flex-direction: column;
    align-items: flex-start;
  }
  .quick-order .product-unit-price .price-actual.has-discount {
    font-size: 13px;
    margin-left: 0px;
  }
  .quick-order .product-unit-price .price-discount {
    font-size: 13px;
  }
}
