.sc_group_products_filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 0.5rem 0;
  margin-bottom: 20px;
  background-color: #fff;
}
.sc_group_products_filter.is-review {
  background-color: hsla(0, 0%, 100%, 0.5);
}
.sc_group_products_filter .input-group-suffix > button {
  height: 36px;
}
.sc_group_products_filter .mtf-item .form-control:focus {
  border-color: #c4c9d0;
  box-shadow: none;
}
.sc_group_products_filter .mtf-item .form-control {
  font-size: 13px;
}
.sc_group_products_filter .filter-sorting > span,
.sc_group_products_filter .filter-sorting > select {
  font-size: 13px;
}
.sc_group_products_filter .filter-sorting > span {
  margin-right: 0.5rem;
  min-width: 85px;
}
.sc_group_products_filter .mf-container {
  margin-right: 10px !important;
}
.sc_group_products .tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sc_group_products .tab-item {
  padding: 5px 10px;
  transition: all 0.5s cubic-bezier(0.38, 0.8, 0.32, 1.07);
  cursor: pointer;
  background-color: #f6f6f6;
  color: var(--color-secondary);
  margin: 2px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.sc_group_products .tab-item.active {
  color: #fff;
  background-color: var(--color-secondary);
}
.sc_group_products_filter .review-mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}
.sc_group_products_filter .review-mode > button {
  color: #fff;
  background-color: var(--color-hotline);
  border: none;
  border-radius: 0;
  padding: 0.475rem 0.75rem;
  outline: none;
  height: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.sc_group_products_filter .review-mode .review.active {
  background-color: var(--color-secondary);
}
.sc_group_products_filter .filter-sorting {
  display: flex;
  align-items: center;
}
.sc_group_products_filter .filter-sorting select {
  width: calc(100% - 85px);
}
.sc_group_products_filter .review-mode.active {
  width: 100%;
}
.sc_group_products_filter .review-mode.active > button {
  width: 15%;
  margin-left: 10px;
}
.sc_group_products .tab-custom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sc_group_products .tab-custom .tab-item {
  position: relative;
}
.sc_group_products .number_result_filter {
  position: absolute;
  top: 3px;
  right: 3px;
  color: var(--color-secondary);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.sc_group_products .tab-item.active .number_result_filter {
  color: #fff;
}
.sc_group_products .part-review {
  margin-bottom: 20px;
}
.sc_group_products .part-review:last-child {
  margin-bottom: 0px;
}
.sc_group_products .part-review .title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  color: #777;
}
.sc_group_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.sc_group_footer .submit {
  background-color: var(--color-secondary);
  color: #fff;
  width: 180px;
  max-width: 100%;
  height: 45px;
  border: none;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}
.sc_group_footer .submit > i {
  margin-right: 0.5rem;
}
.sc_group_footer .total-area > strong {
  font-size: 15px;
  text-transform: uppercase;
}
.sc_group_footer .total-area > p {
  margin-bottom: 0px;
}
.sc_group_footer .total-area {
  text-align: center;
  padding: 15px 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sc_group_footer .total-area .total {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-primary);
}
.sc_group_footer .total-area .note {
  font-size: 12px;
  margin-top: 0.5rem;
}
.sc_group_list {
  width: 100%;
  margin-top: 2px;
  background-color: #fff;
  padding: 10px;
}
.sc_group_products .optional-tab{
  background-color: #fff;
}
.sc_group_products .optional-tab.tab-item.active{
  background-color: var(--color-primary);
}
@media (min-width: 1281px) {
  .sc_group_products_filter .mf-container {
    width: 80%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }
  .sc_group_products_filter .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 5px;
    margin: 0px;
  }
  .sc_group_products_filter .filter-sorting {
    width: 30%;
  }
  .sc_group_products_filter .review-mode {
    width: 15%;
  }
  .sc_group_products .tab-custom .search-tab > strong {
    display: none;
  }
}
@media (max-width: 1280px) {
  .sc_group_products .tab-custom {
    width: 100%;
  }
  .sc_group_products .tab-custom .search-tab > i {
    margin-right: 0.2rem;
  }
}
@media (max-width: 576px) {
  .sc_group_products .number_result_filter {
    display: none;
  }
  .sc_group_products_filter {
    flex-direction: column;
    padding: 10px;
  }
  .sc_group_products_filter .mf-container,
  .sc_group_products_filter .mtf-item {
    margin-right: 0px !important;
  }
  .sc_group_products_filter .filter-sorting {
    width: 100%;
  }
  .sc_group_products_filter .review-mode {
    margin-left: 0px;
    margin-top: 10px;
    flex-direction: column;
  }
  .sc_group_products_filter .review-mode.active {
    margin-top: 0px;
  }
  .sc_group_products_filter .review-mode.active > button,
  .sc_group_products_filter .review-mode {
    width: 100%;
    margin-left: 0px;
  }
  .sc_group_products_filter .review-mode .review.active {
    margin-top: 7px;
  }
  .sc_group_products .tabs {
    margin-bottom: 20px;
  }
  .sc_group_products .tabs .tab-item {
    width: 48.8%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    padding: 10px 5px;
  }
  .sc_group_products .tab-custom .tab-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sc_group_footer {
    flex-direction: column;
  }
  .sc_group_footer .total-area,
  .sc_group_footer .submit {
    width: 100%;
  }
  .sc_group_footer .submit {
    margin-top: 1rem;
  }
}
