.sc_stop_working{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    min-height: 100vh;
}
.sc_stop_working .content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    border-top: 10px solid var(--color-primary);
}
.sc_stop_working .content .logo > img{
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-bottom: 1rem;
}
.sc_stop_working .content .notify> span{
    font-size: 17px;
    color: var(--color-primary);
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
}
.sc_stop_working .content .notify{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 1rem;
}
.sc_stop_working .content .des{
    font-size: 15px;
}
@media (max-width: 769px) {
    .sc_stop_working .content{
        flex-direction: column;
        margin: 0 1rem;
    }
    .sc_stop_working .content .notify{
        align-items: center;
        margin: 0;
    }
    .sc_stop_working .content .des{
        text-align: justify;
    }
    .sc_stop_working .content .logo > img{
        width: 250px;
        height: 250px;
    }
}