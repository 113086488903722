.sc_input_radio{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid rgb(233, 233, 233);
    padding: 5px 2px;
    margin: 2px;
}
.sc_input_radio_label{
    margin-left: 5px !important;
    margin-bottom: 0px !important;
    padding: 5px 0px;
    font-weight: normal !important;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 13px;
}