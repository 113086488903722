.form-control:focus {
    color: var(--color-secondary);
    background-color: #fff;
    border-color: var(--color-secondary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(80 58 32 / 22%);
}
.form-control{
    height: 40px;
    color: #464646;
    font-weight: 500;
}
.sc_editor_blog .blog-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0px;
}
.sc_editor_blog textarea.form-control {
    min-height: 100px;
}
.sc_editor_blog .blog-row .label{
    min-width: 100px;
    height: 40px;
    /* padding: 0.875rem; */
    background-color:var(--color-secondary);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
}
.sc_editor_blog .blog-row .label.inactive{
    background-color: #c7c7c7;
    color: var(--color-secondary);
    cursor: pointer;
}
.sc_editor_blog .form-check-input{
    position: relative;
}
.sc_editor_blog .checkbox{
    padding: 0.875rem 0px;
    cursor: pointer;
}
.sc_editor_blog .dropzone{
    padding: 5px;
    border: 1px solid #c4c4c4;
    width: 100%;
    background: white;
}
.sc_editor_blog .dropzone img{
    width: 100%;
    max-width: 150px;
    margin: 0px 0px 5px;
    background: rgb(241, 241, 241);
    min-width: 150px;
    min-height: 120px;
    max-height: 120px;
    object-fit: cover;
    border: 1px solid #c4c4c4;
}
.sc_editor_blog .dropzone .btn-upload{
    width: 150px;
    height: 120px;
    padding: 0.875rem;
    background-color: #e7e7e7;
    color: var(--color-secondary);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    margin: 0px;
    line-height: 7;
}
.group-input{
    position: relative;
    margin-bottom: 0.5rem;
}
.group-input label{
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0px 5px;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    opacity: 0;
}
.group-input .form-control:focus ~ label, .group-input label.active{
    opacity: 1;
    top: -10px;
    font-size: 14px ;
    color: var(--color-secondary);
    font-weight: bold;
    background: #f1f1f1;
}
.group-input .Select-control{
    margin-bottom: 0px !important;
    width: 100%;
    border-radius: 0px
}
.group-input .Select{
    width: 100%;
}
.group-input .form-control{
    margin-bottom: 0px !important;
}
/* .sc_editor_blog .ck-editor__main>.ck-content{
    max-height: 800px;
} */
.mmd-confirm>div>.modal{
    z-index: 10001;
}
.mmd-confirm>div>.modal-backdrop{
    z-index: 10000;
}
.sc_editor_blog .relative_tab .btn-review{
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    outline: none;
    border: none;
    background-color: var(--color-secondary) ;
    color: #fff;
    float: right;
}
.sc_editor_blog .relative_tab .btn-review:focus{
    outline: none;
}
.sc_editor_blog .relative_tab .mtf-item.col-lg-3{
    max-width: 100%;
    flex: 100%;
    padding: 0px;
}
.sc_editor_blog .relative_tab .mf-container{
    padding:  0px;
}
.sc_editor_blog .relative_tab .row{
    margin-left: 0px;
    margin-right: 0px;
}
.imgFn-editor .btn-external, .md-img-fnc .btn-external{
    width: 25px;
    height: 25px;
    left: 0px;
    bottom: 5px;
    cursor: pointer;
    color: white;
    background: var(--color-secondary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
.imgFn-editor .btn-avatar, .md-img-fnc .btn-avatar{
    width: 25px;
    height: 25px;
    right: 0px;
    bottom: 5px;
    cursor: pointer;
    color: white;
    background: var( --color-primary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
.imgFn-editor .btn-delete, .md-img-fnc .btn-delete{
    width: 25px;
    height: 25px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    color: white;
    background: var( --color-danger);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sc_editor_blog .btn-fnc{
    width: 25px;
    height: 25px;
    right: 0px;
    top: 0px;
    position: absolute;
    cursor: pointer;
    color: white;
    background: rgb(249, 177, 21);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.md-img-fnc .modal-backdrop{
    z-index: 1050;
}
.md-img-fnc .modal{
    z-index: 1061;
}
.md-img-fnc .modal.show .modal-dialog{
    top: 80px;
}
.md-img-fnc img{
    max-width: 300px;
    max-height: 200px;
    object-fit: cover;
}
.sc_editor_blog .ck-content{
    min-height: 460px;
}