.search-session{
    margin-bottom: 30px;
}
.search-session:last-child{
    margin-bottom: 0px;
}
.search-session .title{
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-text-bold);
    text-align: left;
}