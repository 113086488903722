.product-grid .sc_card_search_blog .description{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 75px;
    margin-bottom: 0px;
    text-align: justify;
}
.product-grid .sc_card_search_blog .details{
    padding: 15px
}
.sc_card_search_blog .product-title{
    margin: 10px 0px;
    text-align: left;
    padding: 0px !important;
    height: 50px !important;
}
.sc_card_search_blog .product-title a{
    font-size: 15px;
    font-weight: 600;
    color: var(--color-text-bold);
    text-decoration: none!important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5;
    text-overflow: ellipsis;
}
.sc_card_search_blog .post-date{
    text-align: left;
}
@media (min-width: 1281px){
    .product-grid .sc_card_search_blog .product-item{
        padding-bottom: 0px !important;
    }
}
@media (max-width: 576px) {
    .sc_card_search_blog .picture{
        width: 35% !important;
        height: 120px !important;
        padding: 10px !important;
    }
    .sc_card_search_blog{
        background-color: #fff;

    }
    .sc_card_search_blog .details{
        width: 65% !important;
        padding: 10px 10px 10px 0px!important;
    }
    .sc_card_search_blog .picture>a{
        height: 100%;
    }
    .sc_card_search_blog .product-item{
        height: auto !important;
    }
    .product-grid .sc_card_search_blog .description{
        min-height: 0px;
        font-size: 13px;
        line-height: 1.5;
    }
    .sc_card_search_blog .product-title a{
        display: block !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.5;
        text-overflow: ellipsis;
        white-space: pre-wrap !important;
    }
    .sc_card_search_blog .product-title{
        height: auto !important;
        max-height: 50px;
    }
}