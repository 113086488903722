td.toggle-mobile {
  max-width: 50px;
}
.toggle-mobile-btn {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.25em rgb(67 71 85 / 27%), 0 0.25em 1em rgb(90 125 188 / 5%);
}
.product-unit-price{
    white-space: nowrap;
}
/* .product-coupon::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 0px;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 5px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 100%;
  pointer-events: none;
}
.product-coupon::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  right: 0px;
  right: -10px;
  transform: translate(-50%, -50%) rotate(225deg);
  border: 5px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 100%;
  pointer-events: none;
} */
.product-coupon {
  padding: 0.25rem;
  background-color: var(--color-coupon-background);
  color: var(--color-coupon);
  font-weight: 500;
  position: relative;
  width: fit-content;
  margin-top: 0.5rem;
  font-size: 12px;
}
.product-inventory-msg{
  color: var(--color-danger);
  font-weight: 500;
  font-size: 12px;
  padding: 2px 0px;
}
.cart .product-picture .product-iventory-mobile {
  display: none;
}
.product-attributes{
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px ;
  padding: 2px 6px;
  color: var(--color-primary-bold);
  background-color: var(--color-product-attribute-cart);
  cursor: pointer;
  font-weight: bold;
}
.product-item-attribute{
  margin-right: 0.5rem;
}
.product-item-attribute>span{
  margin-left: 0.5rem;
}
.product-attributes:hover{
  background-color: var(--color-product-attribute-cart-overlay);
}
.product-attributes.disabled{
  color: var(--color-secondary);
  background-color: #fff;
}
.product-attributes:hover{
  background-color: #fff;
}
.footable-row-detail-value .product-attributes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0rem;
  padding: 0px;
}
.cart .product-picture-container {
  position: relative;
  width: 60px;
}
.cart .product-picture-container > span {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: none;
}
@media (max-width: 576px) {
  .cart .product-picture .product-iventory-mobile {
    display: block;
  }
  .cart .product-picture-container>span{
    display: block;
  }
}