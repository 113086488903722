.overview .product-coupon {
  padding: 0.25rem;
  background-color: var(--color-coupon-background);
  color: var(--color-coupon);
  /* font-weight: 600; */
  position: relative;
}
.overview .product-price {
  margin-right: 0.5rem !important;
}
.overview .price-value-coupon {
  color: var(--color-coupon);
  margin-right: 0.5rem;
}
.overview .price-value.origin {
  text-decoration: line-through;
  font-size: 15px;
}
.overview .add-to-wishlist-button.active{
  background-color: var(--color-active-wishlist);
}
@media (max-width: 769px) {
  .overview .space {
    display: none;
  }
}
@media (max-width: 576px) {
  .product-details-page .productAddedToCartOptions-group {
    align-items: center;
  }
  .product-details-page .productAddedToCartOptions-group .title-group {
    margin-bottom: 0.5rem;
    margin-right: 0rem;
    background-color: var(--color-title-attribute-mobile);
    width: 100%;
    justify-content: center;
  }
  .product-details-page .product-reviews-overview{
    margin: 0 0 5px;
  }
  .product-details-page .productAddedToCartOptions{
    margin: 1rem 0rem;
  }
  .overview .product-coupon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
  }
}
