.sc_editor_banner{
    padding: 5px;
    border: 1px solid #f0f0f0;
    width: 100%;
    background: #fff;
}
.sc_editor_banner .btn-upload, .sc_editor_banner .btn-add-new {
    max-width: 100px;
    height: 40px;
    padding: 0.875rem;
    background-color: #8f8a85;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    margin: 0px;
    outline: none;
}
.sc_editor_banner .btn-add-wrapper{
    display: flex;
    justify-content: flex-end;
}
.sc_editor_banner .btn-add-new{
    background-color: #2eb85c;
    border-radius: 0px;
}
.sc_editor_banner img {
    width: 100%;
    max-width: 385px;
    margin: 5px 0px 5px;
    background: rgb(241, 241, 241);
    min-width: 100px;
    /* min-height: 120px; */
    height: 200px;
    object-fit: cover;
    border: 1px solid #c4c4c4;
}
.sc_editor_banner .content{
    display: flex;
    padding: 5px 0px 0px;
    margin-top: 5px;
    border-top: 1px solid #f0f0f0 ;
    flex-wrap: wrap;
}