.mui-page-header-list>button{
  margin-left: 2px;
}
.mui-page-header-list>div{
  margin-left: 2px;
}
.mui-page-header-title{
  font-weight: 700;
  font-size: large;
  color: #3ebec8;
}
.mui-page-header-list{
  flex-wrap: wrap;
  justify-content: flex-end;
}
.mui-page-header-list>button{
  white-space: nowrap;
}