.item-box .buttons-upper .add-to-wishlist-button.active i {
  color: var(--color-active-wishlist);
}

.item-box .buttons-upper .add-to-wishlist-button {
  outline: none;
  border: 0px;
  color: #888;
  background-color: #f9f9f9;
  height: 36px;
  width: 36px;
}

.spc-categories .product-box-add-to-cart-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.spc-categories .product-box-add-to-cart-button i,
.item-box .buttons-lower button i {
  font-size: 13px !important;
  color: #888;
}

.spc-categories input.button-2,
.spc-categories button.button-2:hover i {
  color: var(--color-secondary);
}

.spc-categories input.button-2,
.spc-categories button.button-2 {
  background-color: #f9f9f9;
}

.item-box .picture {
  position: relative;
}

.product-out-of-stock {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--color-danger);
  font-size: 13px;
  font-weight: 600;
}

.spc-categories .picture .product-out-of-stock,
.bestsellers .picture .product-out-of-stock {
  top: 22px;
  left: 22px;
  padding: 2px 6px;
}

.product-grid .picture .product-out-of-stock {
  display: none;
}

.product-grid .buttons-lower .product-out-of-stock {
  color: var(--color-danger) !important;
}

.product-grid .buttons-lower .product-out-of-stock {
  position: relative;
  top: 0;
  left: 0;
  text-transform: uppercase;
  background-color: transparent;
}

.product-grid .buttons-lower .disabled i,
.product-grid .buttons-lower .disabled span {
  display: none;
}

.item-box .product-box-add-to-cart-button.disabled,
.item-box:hover.product-box-add-to-cart-button.disabled {
  background-color: #fff !important;
}

.item-box .product-box-add-to-cart-button.disabled>span,
.item-box:hover.product-box-add-to-cart-button.disabled>span,
.item-box .product-box-add-to-cart-button.disabled>i,
.item-box:hover.product-box-add-to-cart-button.disabled>i {
  color: #88888857 !important;
}

.product-grid .product-item {
  position: relative;
}

.product-grid .product-coupon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 0;
  width: 100%;
}

.product-grid .product-coupon .text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 576px) {
  .product-grid .picture .product-out-of-stock {
    top: 10;
    left: 10;
    padding: 2px 6px;
    position: absolute;
    display: block;
  }

  .product-grid .buttons-lower .disabled i,
  .product-grid .buttons-lower .disabled span {
    display: inline-block;
  }

  .product-grid .buttons-lower .disabled .product-out-of-stock {
    display: none;
  }

  .product-grid .product-coupon {
    position: unset;
    margin-bottom: 0.5rem;
  }

  .product-grid .product-coupon .text {
    width: 100%;
    white-space: pre-line;
  }

  .item-box .product-box-add-to-cart-button.disabled,
  .item-box:hover.product-box-add-to-cart-button.disabled {
    background-color: #f6f6f6 !important;
  }
}

@media (min-width: 481px) {
  .spc-categories .product-box-add-to-cart-button span {
    background-color: transparent !important;
    display: none !important;
  }
}
