.bg-white{
    background-color: #ffffff;
}
.bg-yellow{
    background-color: yellow;
    color: #000;
}
.whitespace-pre-line{
    white-space: pre-line;
}
.table-td-p-5>tbody>tr>td,.table-td-p-5>thead>tr>th{
    padding: 5px;
}
.dFaiCfwW {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dFfdRaiCjcC {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dFaiCjcCfwW {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pAFull{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.has-wrap{
  white-space: normal;
  word-wrap: break-word;
}

.pAFull-loading{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff59;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pAFull-loading i{
  font-size: 20px;
}