.theme-v1{
    height: 100%;
    overflow: auto;
}
.theme-v1 .c-body{
    background-color:  #e4e5e6;
}

.theme-v1 a {
    color: #20a8d8;
    text-decoration: none;
    background-color: transparent;
}

.theme-v1 .c-main{
    padding-top: 1rem;
}
.theme-v1 .btn-primary{
    background-color: #20a8d8;
    border-color:#20a8d8;
}

.theme-v1 ~ div>.tippy-box .btn-primary{
    background-color: #20a8d8;
    border-color:#20a8d8;
}

.theme-v1 .btn-primary:not(:disabled):not(.disabled):active, .theme-v1 .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
}
.theme-v1 .btn-outline-primary {
    color: #20a8d8;
    border-color: #20a8d8;
}
.theme-v1 .btn-outline-primary:hover {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
}
.theme-v1 .btn-primary:focus, .theme-v1 .btn-primary.focus {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

.theme-v1 .btn-outline-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
}

.theme-v1 .page-link{
    color: #20a8d8;
}
.theme-v1 .page-link:focus{
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}
.theme-v1 .page-item.active .page-link{
    background-color: #20a8d8;
    border-color: #20a8d8;
}

.theme-v1 .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}
.theme-v1 .btn i,.theme-v1 .btn .c-icon{
    margin: 0px;
}

.theme-v1 .card{
    margin-bottom: 4px;
    box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);
}

.theme-v1 .form-control:disabled, .form-control[readonly]{
    background-color: transparent;
}

.theme-v1 .table-bordered th, .theme-v1 .table-bordered td{
    border-color: #a4b7c1;
}

.theme-v1 .dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #20a8d8;
}

.theme-v1 .dropdown-item i {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: #c2cfd6;
    text-align: center;
}
.theme-v1 .react-bs-container-body{
    background-color: #f2f2f2;
}
@media (hover: hover), not all
{
    .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: #fff;
        background: #20a8d8;
    }
}