.sc_tab {
  margin: 0px 0px 20px 0px;
}
.sc_tab .productTab_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.sc_tab .productTab_header .nav_tab {
  padding: 12px 20px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 3px solid #ebedef;
}
.sc_tab .productTab_header .nav_tab.active {
  border-bottom: 3px solid var(--color-secondary);
}
.sc_tab .productTab_content .tab_content {
  padding: 33px 35px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  overflow: hidden;
  text-align: left;
}
.sc_tab .productTab_content .tab_content img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}

.sc_tab .productTab_content .tab_content figcaption {
  text-align: center;
}
.sc_tab .productTab_content .tab_content figure {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 1rem;
  width: fit-content;
}

@media (max-width: 576px){
  .sc_tab .productTab_content .tab_content{
    padding: 33px 20px;
  }
}