.sc_go_quickorder_user {
  position: fixed;
  left: 20px;
  bottom: 65px;
  z-index: 1030;
  width: 50px;
  height: 50px;
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: var( --color-primary);
  color: #fff;
  border-radius: 50px;
  border: 2px solid #fff
}
.sc_go_quickorder_user>div{
    font-size: 16px;
    height: 100%;
}
  