.sc_list{

}
.sc_list .mf-container {
    width: 100%;
    padding: 0px;
}
.sc_list .row{
    margin: 0px;
}
.sc_list .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
}
.sc_list .mf-container .btn{
    height: 36px;
}