.cloudimage-zoomin{
    position: absolute;
    overflow: hidden;
    border-radius: 0px;
    cursor: crosshair;
    width: 150%;
    height: 150%;
}
.picture-link{
    height: 400px;
    overflow: hidden;
}
.picture-link img{
    object-fit: cover;
    width: 100%;
    height: 400px;
}
.close-gallery{
    height: 45px;
    margin-top: 5px;
    margin-left: 5px;
}
.close-gallery>span{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-radius: 50px;
    cursor: pointer;
    color: #fff;
}
.close-gallery>span:hover{
    background-color: #f1f1f12b;
}
.sc_product_gallery .image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height: calc(100vh - 130px);
}