.md-img-fnc .modal-content{
    border-color: transparent;
}
.sc_modal_editor_attribute_image_product .header{
    height: 40px;
    width: 107%;
    background-color: #f6f6f6;
    margin: -1rem 1rem 1rem -1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 10px 10px 0px 0px;
    padding: 1rem;
}
.sc_modal_editor_attribute_image_product .header>span{
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}
.sc_modal_editor_attribute_image_product .header>span:hover{
    background-color: #e0e0e0;
    border-radius: 50px;
}
.sc_modal_editor_attribute_image_product .action{
    margin-bottom: 1rem;
}