.sc_editor_upload_image {
    width: 150px;
    height: 120px;
    padding: 0.875rem;
    background-color: #e7e7e7;
    color: var(--color-secondary);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    margin: 0px;
    line-height: 7;
    display: inline-block;
}
.sc_editor_upload_image.upload-options{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: 100%;
    height: 100px;
    background-color: #fff;
}
.upload-options .upload-local>label{
    color: var(--color-secondary);
    font-weight: 500;
    text-align: center;
    font-size: 13px;
    line-height: unset;
    background-color: #f6f6f6;
    width: 100%;
    text-transform: none;
    margin-bottom: 0;
    padding: 0 0.5rem;
    vertical-align: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-options .upload-local{
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
}
.upload-options .upload-available{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    margin-left: 2rem;
    width: 160px;
}
.upload-options .upload-available>button{
    color: var(--color-secondary);
    font-size: 13px;
}
.upload-options .upload-local>label:hover, .upload-options .upload-available:hover{
    background-color: var(--color-secondary);
    color: #fff;
}
.upload-options .upload-available:hover>button{
    color: #fff;
}
.upload-options .upload-available>button:focus{
    box-shadow: none;
}