.ani-run {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ani-run.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ani-run.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.ani-run.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.ani-run.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.ani-run.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.ani-run.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.ani-run.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.ani-run.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.ani-run.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.ani-run.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@keyframes ani_ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@-webkit-keyframes ani_shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes ani_shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.ani-shake{
  -webkit-animation-name: ani_shake;
  animation-name: ani_shake;
}

@-webkit-keyframes ani_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ani_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ani-heartBeat {
  -webkit-animation-name: ani_heartBeat;
  animation-name: ani_heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes ani_spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes ani_spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ani_pulse {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes ani_pulse {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}