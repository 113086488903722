.sc_toolbar_admin{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    background: var(--color-toolbar-admin-background);
}
.sc_toolbar_admin .right{
    display: flex;
    justify-content: flex-end;
}
.add-new{
    display: flex;
    justify-content: flex-end;
}
.add-new>button, .add-new>button:hover, .status-content>button, .status-content>button:hover{
    background-color: #2eb85c;
    color: #fff;
    border: none;
    border-radius: 0px;
    font-size: 13px;
    padding: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
}
.add-new>button:focus, .status-content>button:focus{
    box-shadow: none;
}