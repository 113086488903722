.myui-spinnerring{
  min-width: 40px;
  min-height: 40px;
  position: relative;
}
.myui-spinnerring>div{
  min-width: 2.3rem;
  padding-top: 3rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  z-index: 1000;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.myui-spinnerring>div::before{
  border-color: rgba(0,0,0,.1);
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: .2em solid rgba(0,0,0,.1);
  width: 2rem;
  height: 2rem;
  margin-left: -1rem;
}
.myui-spinnerring>div::after{
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-animation: ani_spin .6s linear;
  animation: ani_spin .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: .2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
  width: 2rem;
  height: 2rem;
  margin-left: -1rem;
}