.mega-menu {
  display: block;
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.header-menu {
  position: relative;
  z-index: 1010;
  text-align: left;
}
.header-menu > ul > li > a,
.header-menu > ul > li > span,
.header-menu > ul > li > div > a,
.header-menu > ul > li > div > span {
  display: block;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-text-bold);
  text-transform: uppercase;
  cursor: pointer;
}
.header-menu.categories-in-side-panel .root-category-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-menu.categories-in-side-panel .root-category-items .menu-collapse-icon {
  padding: 20px;
}
.header-menu .item-collapse{
    display: none;
    background-color: #f6f6f6;
}
.header-menu .item-collapse>a{
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 13px;
}
.header-menu a.active{
    color: var(--color-secondary)
}
@media (max-width: 1280px) {
  .header-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    text-align: left;
    background-color: #f6f6f6;
    z-index: 1070;
    transition: all 0.5s ease;
    transform: translate(-320px);
  }
  .header-menu.open {
    transform: translate(0);
  }
  .header-menu > ul > li > a,
  .header-menu > ul > li > span,
  .header-menu > ul > li > div > a,
  .header-menu > ul > li > div > span {
    padding: 5px 15px;
  }
  .header-menu > ul li {
    background: #fff;
  }
  .header-menu > ul > li > a,
  .header-menu > ul > li > div > a {
    border: none;
  }
  .header-menu > ul li > a,
  .header-menu > ul li > span,
  .header-menu > ul > li > div > a,
  .header-menu > ul > li > div > span {
    display: block;
    position: relative;
    z-index: 0;
    padding: 20px;
    line-height: 19px;
    color: var(--color-text-bold);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .header-menu .close-menu,
  .header-menu .back-button {
    background-color: #f6f6f6;
    cursor: pointer;
  }
  .header-menu.open {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }
  .header-menu .close-menu span,
  .header-menu .back-button span {
    display: block;
    height: 50px;
    background: url(/images/ajuma/close.png) right center no-repeat;
    font-size: 0;
  }
}
@media (min-width: 1281px) {
  .header-menu.categories-in-side-panel .category-menu-item,
  .header-menu.categories-in-side-panel .root-category-items {
    display: none;
  }
  .header-menu > ul > li {
    display: inline-block;
    vertical-align: middle;
  }
  .header-menu.categories-in-side-panel
    .root-category-items
    .menu-collapse-icon {
    display: none;
  }
}
@media (max-width: 576px) {
  .header-menu > ul > li > a,
  .header-menu > ul > li > span,
  .header-menu > ul > li > div > a,
  .header-menu > ul > li > div > span {
    font-size: 13px;
  }
  .header-menu > ul {
    font-size: 13px;
  }
}
