.sc_card_promotion {
  width: 100%;
  padding: 0.5rem;
}

.sc_card_promotion .content {
  padding: 0.5rem;
  width: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.no-slide .sc_card_promotion .content {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 300px;
  overflow: hidden;
}

.sc_card_promotion .content .product-picture {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.sc_card_promotion .content .product-picture>a {
  width: 100%;
  height: 100%;
}

.sc_card_promotion .content .product-picture>a>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sc_card_promotion .content .product-name {
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sc_card_promotion .content .actions {
  /* margin-top: 0.5rem; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 100%; */
}

.sc_card_promotion .content .actions>button {
  width: 30px;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0px;
  border-radius: 50px;
  margin-right: 0.5rem;
  background-color: rgb(246 246 246);
  color: #888;
  font-size: 13px;
}

.sc_card_promotion .content .actions>button:last-child {
  margin-right: 0;
}

.sc_card_promotion .content .actions>button:hover {
  color: var(--color-secondary-lighter);
}

.sc_card_promotion .content .actions .add-cart.disabled {
  color: #88888857;
  background-color: #f6f6f6;
}

.sc_card_promotion .content:hover .actions .add-cart {
  color: var(--color-secondary-lighter);
}

.sc_card_promotion .content .actions .add-wish.active {
  color: var(--color-active-wishlist);
}

.sc_card_promotion .content .product-price {
  /* position: absolute; */
  /* top: 0.5rem; */
  /* left: 0.5rem; */
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 1);
  width: fit-content;
  color: var(--color-primary);
}

.sc_card_promotion .content .product-price .discount-price {
  color: var(--color-coupon);
  font-weight: bold;
  margin-right: 0.5rem;
}

.sc_card_promotion .content .product-price .actual-price.origin {
  font-size: 13px;
  text-decoration: line-through;
}

.sc_card_promotion .ftr {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sc_card_promotion .product-coupon {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin-top: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.sc_card_promotion .product-coupon .text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sc_card_promotion .product-coupon>img {
  width: 25px;
  margin-right: 0.25rem;
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}
