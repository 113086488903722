.quick-order-combination .sc_order_progress{
    margin-top: 30px;
    margin-bottom: 50px;
}
.quick-order-combination .quickorder-step-tab .tab-item{
    display: block;
    padding: 15px;
    font-size: 13px;
    font-weight: 700;
    text-transform: none;
    cursor: pointer;
    transition: all 0.5s ease;
    background-color: #f6f6f6;
    color: #3333339c;
}
.quick-order-combination .quickorder-step-tab .tab-item.active{
    background-color: #fff;
    color: var(--color-secondary)
}
.quick-order-combination .quickorder-step-tab{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.quick-order-combination .part{
    background-color: #fff;
    padding: 10px;
    width: 100%;
}
.quick-order-combination .part .title{
    background-color: #f9f9f9;
    padding: 10px;
    font-size: 13px;
    color: var(--color-text-bold);
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}
.quick-order-combination .text-complete{
    text-align: center;
}
@media (max-width: 576px) {
    .quick-order-combination .sc_order_progress {
        margin-top: 30px;
        margin-bottom: 70px;
    }
}