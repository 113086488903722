.filtersGroupPanel{
    margin-bottom: 10px !important;
}
.filtersGroupPanel .input-range__slider{
    border: 1px solid #c5c5c5;
    background-color: #f6f6f6;
    border-radius: 4px; 
}
.filtersGroupPanel .input-range__track--active{
    background-color: var(--color-secondary);
}
.filtersGroupPanel .input-range__label-container{
    color: #777;
    font-size: 15px;
}
@media (max-width: 576px) {
    .sc_filter{
        display: none;
    }
}