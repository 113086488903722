.sc_user_quickorder .sc_input_quantity .qty-input,
.sc_user_quickorder .sc_input_quantity {
  width: 100%;
}
.sc_user_quickorder .sc_input_quantity .qty-input {
  margin: 0px !important;
  margin-right: 4px !important;
  height: 36px;
  text-align: left;
}
.sc_user_quickorder .form-control:focus {
  color: inherit;
  border: 1px solid #e9e9e9;
  outline: 0;
  box-shadow: none;
}
.sc_user_quickorder .btn-dangerous {
  background-color: var(--color-danger);
  color: #fff;
  border-radius: 0px;
}
.sc_user_quickorder .btn-dangerous:focus {
  box-shadow: none;
}
.sc_list .item {
  padding: 5px;
}
.sc_list .item:hover {
  background-color: var( --color-primary)45;
  color: var( --color-primary);
}
.sc_user_quickorder .address-grid {
  margin: 10px 10px 0px;
}
.sc_user_quickorder .delete {
  width: 5%;
}
.sc_list .btn-select {
  background-color: #f5f5f5;
  max-width: 40px;
  min-width: 40px;
  /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
  margin-left: 5px;
}
.sc_list .btn-select:focus {
  box-shadow: none;
}
.sc_list .btn-select.active {
  background-color: var(--color-secondary);
  color: #fff;
}
.sc_user_quickorder .btn-select-material > span::before {
  content: "Chọn hàng";
}
.sc_user_quickorder .btn-select-material {
  width: 150px;
  height: 36px;
}
@media (max-width: 1280px) {
  .sc_user_quickorder .btn-select-material > span::before {
    content: "\f002";
    font: normal normal normal 14px/1 FontAwesome;
  }
  .sc_user_quickorder .btn-select-material {
    width: 36px;
  }
}
@media (max-width: 576px) {
  .sc_user_quickorder .address-grid {
    margin: 0px;
  }
  .sc_user_quickorder .delete {
    width: 15%;
  }
}
