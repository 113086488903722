.sc_modal_select_photos .content{
    padding: 5px;
    border: 1px solid #f0f0f0;
    width: 100%;
    background: #fff;
    margin-top: 0px;
}
.sc_modal_select_photos .footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sc_modal_select_photos .footer>button{
    background-color: var(--color-secondary);
    color: #fff;
    border: none;
    border-radius: 0px;
    font-size: 13px;
    padding: 0.575rem;
    font-weight: bold;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
    margin: 5px;
    height: 40px;
}
.sc_modal_select_photos .footer .cancel{
    background-color: #ebedef;
    color: var(--color-secondary);
}
.sc_modal_select_photos .footer .insert{
    background-color:  var( --color-primary)
}
.sc_modal_select_photos .footer>button:focus{
    box-shadow: none;
}
.sc_modal_select_photos .tab-header .label {
    padding: 0.575rem !important;
}
.sc_modal_select_photos .content .item{
    width: 24%;
    margin: 5px;
    position: relative;
    cursor: pointer;
}
.sc_modal_select_photos .content .item>img{
    width: 100%;
    object-fit: cover;
    height: 200px;
}
.sc_modal_select_photos .content>div{
    display: flex;
    flex-wrap: wrap;
}
.sc_modal_select_photos .select{
    background-color: #ffc83d;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    color: #fff;
}
.sc_modal_pagination_style{
    margin: 10px;
}
.sc_modal_pagination_style li{
    width: 30px;
    height: 30px;
    background-color: #f6f6f6;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 600;
}
.sc_modal_pagination_style li a{
    outline: none;
}
.sc_modal_pagination_style li.active a{
    color: var(--color-secondary);
    font-weight: 700;
}
.mmui2>div>.modal-backdrop{
    z-index: 1051;
}
.mmui2>div>.modal{
    z-index: 1052;
}
.sc_modal_select_photos .mtf-item{
    /* margin-left: 5px; */
    margin-right: 5px;
    padding-left: 0px !important;
    padding-right: 5px !important;
    max-width: 24% !important;
    width: 24%
}
.sc_modal_select_photos .mtf-item:first-child{
    margin-left: 15px;
}
.sc_modal_select_photos .mtf-item .Select-control{
    border-radius: 0px;
    border: 1px solid #e9e9e9
}
.sc_modal_select_photos .card{
    border: 0px;
    margin-bottom: 0px;
}
.sc_modal_select_photos .input-group-text{
    font-size: 13px;
    padding: 6px;
}