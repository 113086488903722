.product-variation__tick:before {
  border: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid var(--color-primary-bold);
  content: "";
  position: absolute;
  right: -0.9375rem;
  bottom: 0;
}
.product-variation__tick > .icon-tick-bold {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 8px;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.productAddedToCartOptions-group {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
/* .productAddedToCartOptions-group .title-group {
  margin-bottom: 1rem;
} */
.productAddedToCartOptions-group .items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.productAddedToCartOptions-group .productAddedToCartOptions-item {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  border: 1px solid var(--color-border-light-secondary);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.productAddedToCartOptions-group .productAddedToCartOptions-item:hover,
.productAddedToCartOptions-group .productAddedToCartOptions-item.active{
  border-color: var(--color-primary-bold);
}
.productAddedToCartOptions-group .productAddedToCartOptions-item.disabled {
  color: rgba(0,0,0,.26);
  cursor: not-allowed;
}
.productAddedToCartOptions-group .productAddedToCartOptions-item.disabled:hover{
  border: 1px solid var(--color-border-light-secondary);
}