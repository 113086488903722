.sc_order_progress {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.sc_order_progress .step {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sc_order_progress .step:first-child {
  width: 40px;
  justify-content: flex-start;
}
.sc_order_progress .step .circle {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50px;
  color: #777;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: .3s all ease-in;
}
.sc_order_progress .step .circle .description {
  position: absolute;
  bottom: -30px;
  right: -65px;
  width: 150px;
  display: flex;
  justify-content: center;
  color: #777;
  font-weight: normal;
}
.sc_order_progress .step .circle.active,
.sc_order_progress .step .circle.passed {
  background-color: var(--color-primary);
  color: #fff;
  transition: .3s all ease-in;
}
.sc_order_progress .step .circle.active .description {
  color: var(--color-primary);
}
.sc_order_progress .step .line {
  font-size: 10px;
  width: calc(100% - 25px);
  margin: 0 20px;
  border-top: 2px solid #a1a5ab;
  transition: .3s all ease-in;
}
.sc_order_progress .step:first-child .line {
  display: none;
}
.sc_order_progress .step .line.active,
.sc_order_progress .step .line.passed {
  border-top: 2px solid var(--color-primary);
  transition: .3s all ease-in;
}
.sc_order_progress .step .ico-tick {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}
@media (min-width: 1281px) {
    .sc_order_progress{
        padding: 0px 300px;
    }
}
@media (max-width: 1280px) {
  .sc_order_progress .step .circle .description {
    font-size: 12px;
    bottom: -30px;
    right: -45px;
    width: 115px;
    font-weight: 500;
    text-align: center;
  }
  .sc_order_progress {
    padding: 0px 10px;
  }
}
@media (max-width: 576px) {
  .sc_order_progress .step .circle .description {
    bottom: -45px;
    right: -15px;
    width: 65px;
  }
  .sc_order_progress .step .line{
    margin: 0 2px;
  }
  .sc_order_progress .step .circle{
      min-width: 35px;
      max-width: 35px;
      height: 35px;
  }
  .sc_order_progress .step:first-child {
    width: 35px;
    justify-content: flex-start;
  }
}
