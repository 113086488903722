.footable-row-detail-row.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}
.footable-row-detail-row.action .footable-row-detail-name {
  margin-left: 0.5rem;
}
.footable-row-detail-row.action .footable-row-detail-delete {
  float: right;
  color: var(--color-danger);
}

.wishlist .cart .product-picture{
  text-align: left;
}
.wishlist .cart .subtotal, .wishlist .cart .quantity, .wishlist .cart .product.attributes{
  text-align: center;
}
.wishlist .cart th{
  padding: 20px 15px;
}
.wishlist .cart .quantity .quantity-control{
  justify-content: center;
}
.wishlist .product-attributes{
  margin-left: 0px;
}
.wishlist .wishlist-add-to-cart-button{
  float: right;
  min-width: 145px;
  border: none;
  background-color: var(--color-secondary);
  padding: 13px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: none;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
  position: relative;
}
.wishlist .wishlist-add-to-cart-button i{
  display: none;
}
.wishlist .wishlist-add-to-cart-button.process i{
  display: inline-block;
}
.wishlist .wishlist-add-to-cart-button.process:focus{
  transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
  animation: anim-moema-1 0.3s forwards;
  background-color: var(--color-primary);
}
@media (max-width: 576px) {
  .wishlist .wishlist-add-to-cart-button{
    float: none;
  }
}
@keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.1, 1.1, 1);
		transform: scale3d(1.1, 1.1, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}