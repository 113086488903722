.sc_card_product {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-width: 0 1px 1px 0;
  display: flex;
  height: 100%;
  min-height: 172px;
  .pic {
    width: 37%;
    margin: 0;
    padding: 20px 10px 20px 20px;
    z-index: 1;
    overflow: hidden;
    position: relative;
    a {
      display: block;
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
      -webkit-transform: translateZ(0) scale(1);
    }
    .out-of-stock {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 5px 10px;
      background-color: rgba(255, 255, 255, 0.7);
      color: var(--color-danger);
      font-size: 13px;
      font-weight: 600;
    }
  }
  .det {
    padding: 20px 20px 20px 10px;
    text-align: left;
    width: 63%;
    flex-direction: column;
    display: flex;
    .rating-box {
      display: inline-block;
      margin: 0 0 10px;
    }
    .rating {
      background: url(/images/ajuma/rating-sprite.png) 0 0 repeat-x;
      width: 80px;
      height: 13px;
      div {
        background: #ffc600 url(/images/ajuma/rating-sprite.png) 0 100% repeat-x;
        height: 13px;
      }
    }
    .p-title {
      height: 20px;
      margin: 0 0 8px;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-text-bold);
      overflow: hidden;
    }
    .info {
      width: 100%;
      flex: 1;
      .prices {
        display: flex;
        align-items: flex-end;
        margin: 0 0 16px;
        height: 22px;
      }
      .actual-price {
        padding: 0 4px;
        vertical-align: middle;
        font-size: 15px;
        font-weight: 700;
        color: var(--color-price);
        &.origin {
          font-size: 13px;
          text-decoration: line-through;
        }
      }
      .discount-price {
        color: var(--color-coupon);
        font-weight: 700;
        font-size: 15px;
        margin-right: 0.5rem;
      }
      .product-coupon {
        width: 100%;
        margin-top: -8px;
        margin-bottom: 0.5rem;
        .text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .actions {
    display: flex;
    .btn {
      color: #888;
      background-color: #f9f9f9;
      outline: none;
      width: 36px;
      height: 36px;
      margin: 0 3px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-size: 13px;
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
    .add-to-wishlist:hover {
      color: var(--color-secondary);
    }
    .product-active {
      color: var(--color-active);
    }
    .product-inactive {
      color: var(--color-danger);
    }
  }
  &:hover {
    .actions .add-to-cart {
      color: var(--color-secondary);
    }
  }
  @media (min-width: 681px) {
  }
  @media (min-width: 481px) {
    .pic {
      width: 40%;
    }
    .det {
      width: 60%;
    }
  }
  @media (max-width: 576px) {
    .pic {
      width: 35%;
      padding: 10px;
      .out-of-stock {
        top: 10px;
        left: 10px;
      }
    }
    .det {
      width: 65%;
    }
  }
}
