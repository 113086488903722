html:not([dir="rtl"]) .form-check-input{
    margin: 0px;
}

.tippy-box[data-theme~='cpopover']{
    pointer-events: all;
    padding: 10px;
}

.tippy-content .cpo-header{
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.tippy-content .cpo-footer{
    margin-top: 10px;
}

.tippy-content .cpo-footer button:last-child{
    margin-left: 10px;
}

.modal-header{
    font-size: 16px;
    font-weight: bold;
}

#_dev_warning_{
    position: fixed;
    top: 0;
    left: 0;
    background: red;
    padding: 0px 10px;
    border-bottom-right-radius: 5px;
    /* border-bottom-left-radius: 5px; */
    color: white;
    z-index: 12345;
    cursor: pointer;
}

.select-language{
    position: absolute;
    top: 0;
    right: 0;
    min-width: 150px;
    /* height: 30px; */
    /* background: black; */
}

.select-language .Select-control{
    border: 0px;
}

.ck.ck-balloon-panel{
    z-index: 1051!important;
}