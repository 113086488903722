.sc_input_quantity {
  display: flex;
  position: relative;
}

.sc_input_quantity .qty-input {
  width: 50px;
  height: 40px;
  text-align: center;
}

.sc_input_quantity>button {
  border: none;
  /* background-color: var(--color-secondary); */
  padding: 10px 15px;
  color: #fff;
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all .2s ease;
}

.sc_input_quantity .quantity-control>button {
  border: none;
  background-color: #ebedef;
  color: var(--color-secondary);
  outline: 1px solid transparent;
  padding: 2px 8px;
  margin-bottom: 1px;
  font-size: 13px;
}

.sc_input_quantity .quantity-control {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
}

.sc_input_quantity .clear-value.text {
  position: absolute;
  bottom: -20px;
  left: 39%;
  font-size: 10px;
  color: var(--color-danger);
  cursor: pointer;
}

.sc_input_quantity .clear-value.text:hover {
  text-decoration: underline;
}

.sc_input_quantity .clear-value.icon {
  position: absolute;
  top: -5px;
  right: 25%;
  font-size: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  color: var(--color-danger);
  background-color: #ebedef;
  /* color: #fff; */
  cursor: pointer;
}
